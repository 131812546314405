import {
  ClozeDisplayStatusMap,
  ExerciseAction,
  ExerciseState,
  CreateExercise,
  Correctness
} from "./types";
import { shuffleArray } from "../random";
import { ClozeDeletion, getAvailableClozes } from "../Cloze/cloze";
import produce from "immer";
import { v4 as uuid } from "uuid";
import { NoteState } from "../Note/types";
import { TaggedNote } from "../TaggedNote/types";

const initialClozeStatuses = (
  availableClozes: ClozeDeletion[],
  note: NoteState
): ClozeDisplayStatusMap => {
  const { hideOne, onlyFirstBlankAnswerable } = note;
  const names = availableClozes.map(c => c.name);
  const hiddenNames = hideOne ? names.slice(0, 1) : names;
  const answerableNames = onlyFirstBlankAnswerable
    ? hiddenNames.slice(0, 1)
    : hiddenNames;
  return availableClozes.reduce<ClozeDisplayStatusMap>((accum, cloze) => {
    if (answerableNames.find(n => n === cloze.name)) {
      accum[cloze.name] = "answerable";
    } else if (hiddenNames.find(n => n === cloze.name)) {
      accum[cloze.name] = "hidden";
    } else {
      accum[cloze.name] = "revealed";
    }
    return accum;
  }, {});
};

export const initialExerciseState = (ex: CreateExercise): ExerciseState => {
  return {
    exerciseId: ex.exerciseId,
    exerciseGroupId: ex.exerciseGroupId,
    order: ex.order,
    note: ex.note,
    availableClozes: ex.availableClozes,
    createdAt: ex.createdAt,
    clozeStatuses: initialClozeStatuses(ex.availableClozes, ex.note),
    markedAnswers: [],
    updatedAt: ex.createdAt
  };
};
// ClozeDisplayStatusMap = map of ClozeName => Status
export const updateExerciseState = (
  exerciseState: ExerciseState,
  exerciseAction: ExerciseAction
): ExerciseState => {
  const { availableClozes, clozeStatuses } = exerciseState;
  if (exerciseState.exerciseId !== exerciseAction.exerciseId) {
    console.warn(
      `Trying to update exerciseState ${exerciseState.exerciseId} with 
      action for ${exerciseAction.exerciseId}. This is a no-op`
    );
    return exerciseState;
  }
  if (exerciseAction.createdAt < exerciseState.updatedAt) {
    console.warn(
      `Trying to update exerciseState with an old action. This is a no-op`
    );
    return exerciseState;
  }
  switch (exerciseAction.type) {
    case "CREATE_EXERCISE":
      return exerciseState;
    case "SKIP":
      const nextAnswerableCloze = availableClozes.find(
        c => clozeStatuses[c.name] === "answerable"
      );
      const names = availableClozes
        .map(v => v.name)
        .filter(
          name =>
            clozeStatuses[name] === "hidden" ||
            clozeStatuses[name] === "revealed"
        );
      const nextClozeName = names[0];
      return produce(exerciseState, state => {
        if (nextAnswerableCloze) {
          state.clozeStatuses[nextAnswerableCloze.name] = "skipped";
        }
        if (nextClozeName) {
          state.clozeStatuses[nextClozeName] = "answerable";
        }
        state.markedAnswers.push({ ...exerciseAction, isCorrect: false });
        state.updatedAt = exerciseAction.createdAt;
      });
    case "SUBMIT_ANSWER":
      // User actually supplied an answer
      const matchedCloze = availableClozes.find(
        c =>
          clozeStatuses[c.name] === "answerable" &&
          c.answer.toLocaleLowerCase() ===
            exerciseAction.answer.toLocaleLowerCase()
      );

      if (matchedCloze) {
        const names = availableClozes
          .map(v => v.name)
          .filter(
            name =>
              clozeStatuses[name] === "hidden" ||
              clozeStatuses[name] === "revealed"
          );
        const nextClozeName = names[0];
        return produce(exerciseState, state => {
          state.clozeStatuses[matchedCloze.name] = "answeredCorrect";
          if (nextClozeName) {
            state.clozeStatuses[nextClozeName] = "answerable";
          }
          state.markedAnswers.push({ ...exerciseAction, isCorrect: true });
          state.updatedAt = exerciseAction.createdAt;
        });
      } else {
        // ExerciseAction didn't match cloze, consider it wrong
        return produce(exerciseState, state => {
          state.markedAnswers.push({ ...exerciseAction, isCorrect: false });
          state.updatedAt = exerciseAction.createdAt;
        });
      }
  }
};

///
/// Helper functions
///

export const exerciseStateIsAnswerable = (exerciseState: ExerciseState) => {
  return Object.values(exerciseState.clozeStatuses).some(
    v => v === "answerable"
  );
};

export const exerciseStateLastAnsweredAt = (
  exercise: ExerciseState
): Date | null => {
  if (exercise.markedAnswers.length === 0) {
    return null;
  } else {
    return exercise.markedAnswers
      .map(ans => ans.createdAt)
      .reduce((prev, curr) => (curr > prev ? curr : prev));
  }
};

export const exerciseStateIsCorrect = (
  exercise: ExerciseState
): Correctness => {
  // NOTE: This assumes an exercise is correct if none of the questions were skipped
  // Might want to reconsider this somehow
  const allCorrect = Object.values(exercise.clozeStatuses).every(
    v => v === "answeredCorrect"
  );
  const anyWrong = Object.values(exercise.clozeStatuses).some(
    v => v === "answeredWrong" || v === "skipped"
  );
  const noMistake = exercise.markedAnswers.every(ans => ans.isCorrect);
  if (allCorrect && noMistake) {
    return "CORRECT";
  } else if (anyWrong) {
    return "INCORRECT";
  } else {
    return "PARTIAL_CORRECT";
  }
};

export const createExerciseFromNote = (
  note: TaggedNote,
  exerciseGroupId: string,
  order: number
): CreateExercise => {
  const noteAvailableClozes = getAvailableClozes(note.content);
  const availableClozes = note.answerInOrder
    ? noteAvailableClozes
    : shuffleArray(noteAvailableClozes);
  const createdAt = new Date();
  return {
    actionId: uuid(),
    type: "CREATE_EXERCISE",
    exerciseId: uuid(),
    note: {
      noteId: note.noteId,
      createdAt: note.createdAt,
      lastUpdatedAt: note.lastUpdatedAt,
      content: note.content,
      tagIds: note.tags.map(t => t.tagId),
      currentDuration: note.currentDuration,
      upcomingTimestamp: note.upcomingTimestamp,
      isDeleted: note.isDeleted,
      answerInOrder: note.answerInOrder,
      hideOne: note.hideOne,
      onlyFirstBlankAnswerable: note.onlyFirstBlankAnswerable
    },
    availableClozes,
    createdAt,
    exerciseGroupId,
    order
  };
};
