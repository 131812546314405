import { NoteState } from "../Note/types";
import * as t from "io-ts";
import { DateCodec } from "../IOTypes";

export const Correctness = t.keyof({
  CORRECT: null,
  PARTIAL_CORRECT: null,
  INCORRECT: null
});
export type Correctness = t.TypeOf<typeof Correctness>;
export const ClozeDeletion = t.type({
  name: t.string,
  answer: t.string,
  hint: t.union([t.string, t.null])
});
export type ClozeDeletion = t.TypeOf<typeof ClozeDeletion>;
// ---------------------------
// Exercise Actions
// ---------------------------
// This represents all the actions that can be applied to an exercise,
// including creating the exercise itself.
export const CreateExercise = t.type({
  actionId: t.string,
  type: t.literal("CREATE_EXERCISE"),
  exerciseId: t.string,
  createdAt: DateCodec,
  exerciseGroupId: t.string,
  order: t.number,
  note: NoteState,
  availableClozes: t.array(ClozeDeletion)
});
export type CreateExercise = t.TypeOf<typeof CreateExercise>;

export const SubmitAnswer = t.type({
  actionId: t.string,
  type: t.literal("SUBMIT_ANSWER"),
  answer: t.string,
  createdAt: DateCodec,
  exerciseId: t.string
});
export type SubmitAnswer = t.TypeOf<typeof SubmitAnswer>;

export const Skip = t.type({
  actionId: t.string,
  type: t.literal("SKIP"),
  familiar: t.boolean, // Whether the user knew the answer or not, self-reported
  createdAt: DateCodec,
  exerciseId: t.string
});
export type Skip = t.TypeOf<typeof Skip>;

export const AnswerAction = t.union([SubmitAnswer, Skip]);
export type AnswerAction = t.TypeOf<typeof AnswerAction>;

export const ExerciseAction = t.union([SubmitAnswer, Skip, CreateExercise]);
export type ExerciseAction = t.TypeOf<typeof ExerciseAction>;

// ---------------------------
// Exercise State
// ---------------------------
// This represents the derived properties of an exercise.
// It is not meant to be synced across sites; instead, it
// should always be re-derived by reducing the exercise actions

const ClozeDisplayStatus = t.keyof({
  revealed: null,
  hidden: null,
  highlighted: null,
  answerable: null,
  answeredCorrect: null,
  answeredWrong: null,
  skipped: null
});
export type ClozeDisplayStatus = t.TypeOf<typeof ClozeDisplayStatus>;

export const ClozeDisplayStatusMap = t.record(t.string, ClozeDisplayStatus);
export type ClozeDisplayStatusMap = t.TypeOf<typeof ClozeDisplayStatusMap>;

export const MarkedAnswer = t.intersection([
  AnswerAction,
  t.type({ isCorrect: t.boolean })
]);
export type MarkedAnswer = t.TypeOf<typeof MarkedAnswer>;

export const ExerciseState = t.type({
  exerciseId: t.string,
  exerciseGroupId: t.string,
  order: t.number,
  note: NoteState,
  availableClozes: t.array(ClozeDeletion),
  createdAt: DateCodec,
  clozeStatuses: ClozeDisplayStatusMap,
  markedAnswers: t.array(MarkedAnswer),
  updatedAt: DateCodec
});
export type ExerciseState = t.TypeOf<typeof ExerciseState>;
