import { ClozeDisplayStatusMap } from "../Exercise/types";
import { getAvailableClozes } from "../Cloze/cloze";
import { NoteContentBlock } from "./types";

/**
 * Given some content, returns a cloze display status map that highlights all potential answers.
 *
 * This is used to render a note.
 *
 * @param content
 */
export const highlightAllClozes = (
  content: NoteContentBlock[]
): ClozeDisplayStatusMap => {
  const availableClozes = getAvailableClozes(content);
  return availableClozes.reduce((accum, cloze) => {
    accum[cloze.name] = "highlighted";
    return accum;
  }, {} as ClozeDisplayStatusMap);
};
