import RxDB, { RxDatabase } from "rxdb";
import RxDBReplicationGraphQL from "rxdb/plugins/replication-graphql";
import {
  ExerciseActionCollection,
  exerciseActionCollectionOpts,
  ExerciseCollection,
  exerciseCollectionOpts
} from "../Exercise/database";
import {
  TagActionCollection,
  tagActionCollectionOpts,
  tagCollectionOpts,
  TagStateCollection
} from "../Tag/database";
import {
  serverSyncCollectionOpts,
  ServerSyncStatusCollection
} from "../Store/database";
import {
  TaggedNoteCollection,
  taggedNoteCollectionOpts
} from "../TaggedNote/database";

type DatabaseCollections = {
  tag_states: TagStateCollection;
  tag_actions: TagActionCollection;
  exercises: ExerciseCollection;
  exercise_actions: ExerciseActionCollection;
  server_sync_statuses: ServerSyncStatusCollection;
  tagged_notes: TaggedNoteCollection;
};
export type AppDatabase = RxDatabase<DatabaseCollections>;

export const DATABASE_NAME = "blanknotesdb3";
export const createDb = async (adapter: "idb" | "memory") => {
  switch (adapter) {
    case "idb":
      RxDB.plugin(require("pouchdb-adapter-idb"));
      break;
    case "memory":
      RxDB.plugin(require("pouchdb-adapter-memory"));
      break;
  }

  RxDB.plugin(RxDBReplicationGraphQL);
  const db: AppDatabase = await RxDB.create<DatabaseCollections>({
    name: DATABASE_NAME,
    adapter: adapter
  });
  await Promise.all([
    db.collection(tagCollectionOpts),
    db.collection(tagActionCollectionOpts),
    db.collection(exerciseCollectionOpts),
    db.collection(exerciseActionCollectionOpts),
    db.collection(serverSyncCollectionOpts),
    db.collection(taggedNoteCollectionOpts)
  ]);
  return db;
};
