import styled from "@emotion/styled";
import {
  green300,
  green400,
  green800,
  green900,
  yellow300,
  yellow400,
  yellow800,
  yellow900
} from "../colors";

const ActionCallout = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  p {
    margin: 0;
  }
  strong {
    font-weight: 600;
  }
`;
export const PositiveActionCallout = styled(ActionCallout)`
  background: ${green300};
  border: 1px solid ${green400};
  p {
    color: ${green800};
  }
  strong {
    color: ${green900};
  }
`;
export const NegativeActionCallout = styled(ActionCallout)`
  background: ${yellow300};
  border: 1px solid ${yellow400};

  p {
    color: ${yellow800};
  }
  strong {
    color: ${yellow900};
  }
`;
