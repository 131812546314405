import { shuffleArray } from "../../random";
import styled from "@emotion/styled";
import { grey200, grey500, grey800, white } from "../colors";
import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { Manager, Popper, Reference } from "react-popper";
import tagIcon from "../../assets/tag.svg";
import produce from "immer";
import { v4 as uuid } from "uuid";
import { EditableTag } from "./tag";
import { SaveTag, TagColour, tagColours, TagState } from "../../Tag/types";

const getRandomColor = () => shuffleArray(tagColours)[0];
export const createNewTag = (
  tagName: string,
  tagColor?: TagColour
): SaveTag => {
  return {
    type: "SAVE_TAG",
    tagName,
    actionId: uuid(),
    createdAt: new Date(),
    tagId: uuid(),
    tagColor: tagColor || getRandomColor()
  };
};
const mod = (n: number, m: number) => ((n % m) + m) % m;
const MenuContainer = styled.div`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: ${white};
  padding: 0.5rem 0;
  border: 1px solid ${grey200};
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
  max-height: 256px;
  overflow-y: auto;
  width: 100%;

  .selected {
    background: ${grey200};
  }
`;
const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  user-select: none;
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
  }
  :active {
    background-color: ${grey200};
  }
  :hover {
    background: ${grey200};
  }
  :focus {
    background: ${grey200};
  }
`;
const InputContainer = styled.div`
  background-color: ${white};
  padding: 0.6rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  > img {
    margin-right: 0.3rem;
    fill: ${grey200};
  }
  div {
    box-sizing: border-box;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  input {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    flex-grow: 1;
    flex-basis: 2px;
  }
`;
const TagInput = styled.input`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: ${grey800};
  height: 1.4rem;
  border: none;
  outline: none;
  width: 2px;
  line-height: 1.8rem;
  ::placeholder {
    color: ${grey500};
  }
`;
type TagMenuSelectAction = { type: "Select"; tag: TagState };
type TagMenuCreateAction = { type: "Create"; newTag: SaveTag };
type TagMenuAction = TagMenuSelectAction | TagMenuCreateAction;
const TagMenuActionItem = ({
  action,
  performAction,
  selected
}: {
  action: TagMenuAction;
  performAction: (action: TagMenuAction) => void;
  selected: boolean;
}) => {
  const element = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (selected) {
      element.current && element.current.scrollIntoView({ block: "nearest" });
    }
  }, [selected, element]);
  switch (action.type) {
    case "Create":
      return (
        <MenuItem
          className={selected ? "selected" : "unselected"}
          onClick={() => {
            performAction(action);
          }}
          ref={element}
        >
          New tag: <EditableTag {...action.newTag} />
        </MenuItem>
      );
    case "Select":
      return (
        <MenuItem
          className={selected ? "selected" : "unselected"}
          key={action.tag.tagName}
          onClick={() => {
            performAction(action);
          }}
          ref={element}
        >
          <EditableTag {...action.tag} />
        </MenuItem>
      );
  }
};
const TagMenu = ({
  actions,
  performAction,
  selectedIndex
}: {
  actions: TagMenuAction[];
  performAction: (action: TagMenuAction) => void;
  selectedIndex: number;
}) => {
  return actions.length > 0 ? (
    <MenuContainer>
      {actions.map((act, idx) => (
        <TagMenuActionItem
          key={idx}
          action={act}
          performAction={performAction}
          selected={idx === selectedIndex}
        />
      ))}
    </MenuContainer>
  ) : null;
};
type TagEditorProps = {
  selectedTags: TagState[];
  availableTags: TagState[];
  selectTag: (tags: TagState[]) => void;
  saveTag: (tag: SaveTag) => void;
};
export const TagEditor = ({
  selectedTags,
  availableTags,
  selectTag,
  saveTag
}: TagEditorProps) => {
  const [input, setInput] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const popperRef = useRef(null);
  const [tagColour, setTagColour] = useState(getRandomColor());
  const newTag = createNewTag(input, tagColour);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useOnClickOutside(popperRef, () => {
    setInput("");
    setShowMenu(false);
  });
  useEffect(() => {
    if (input) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [input]);
  const selectedTagNames = selectedTags.reduce((accum, curr) => {
    accum[curr.tagName] = true;
    return accum;
  }, {} as { [index: string]: boolean });
  const createActions: TagMenuAction[] =
    input &&
    !availableTags.find(
      t => t.tagName.toLocaleLowerCase() === input.toLocaleLowerCase()
    )
      ? [{ type: "Create", newTag }]
      : [];
  const selectActions: TagMenuAction[] = availableTags
    .filter(t => {
      if (selectedTagNames[t.tagName]) {
        return false;
      } else if (input) {
        return (
          t.tagName.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) > -1
        );
      } else {
        return true;
      }
    })
    .map(t => ({
      type: "Select",
      tag: t
    }));
  const menuActions = [...selectActions, ...createActions];
  const performAction = (action: TagMenuAction) => {
    switch (action.type) {
      case "Create":
        saveTag(action.newTag);
        setTagColour(getRandomColor());
        setInput("");
        setShowMenu(false);
        break;
      case "Select":
        selectTag([...selectedTags, action.tag]);
        setInput("");
        setShowMenu(false);
        break;
    }
  };
  return (
    <Manager>
      <div ref={popperRef} className={"tagEditor"}>
        <Reference>
          {({ ref }) => (
            <InputContainer ref={ref}>
              <img src={tagIcon} width={24} alt={"search"} />
              {selectedTags.map((t, idx) => (
                <EditableTag
                  {...t}
                  key={t.tagId}
                  onClose={() =>
                    selectTag(
                      produce(selectedTags, d => {
                        d.splice(idx, 1);
                      })
                    )
                  }
                />
              ))}
              <TagInput
                placeholder={selectedTags.length === 0 ? "Add tags" : ""}
                onFocus={() => {
                  setShowMenu(true);
                }}
                value={input}
                onChange={evt => setInput(evt.target.value)}
                onKeyDown={evt => {
                  switch (evt.key) {
                    case "Tab":
                    case "Enter":
                      const selectedAction = menuActions[selectedIndex];
                      if (
                        showMenu &&
                        selectedAction &&
                        !evt.altKey &&
                        !evt.ctrlKey &&
                        !evt.shiftKey &&
                        !evt.metaKey
                      ) {
                        evt.preventDefault();
                        performAction(selectedAction);
                      }
                      break;
                    case "Backspace":
                      if (!input) {
                        selectTag(
                          selectedTags.slice(0, selectedTags.length - 1)
                        );
                      }
                      break;
                    case "Down":
                    case "ArrowDown":
                      if (menuActions.length > 0) {
                        setSelectedIndex(idx =>
                          mod(idx + 1, menuActions.length)
                        );
                      }
                      break;
                    case "Up":
                    case "ArrowUp":
                      if (menuActions.length > 0) {
                        setSelectedIndex(idx =>
                          mod(idx - 1, menuActions.length)
                        );
                      }
                      break;
                    case "Escape":
                      setInput("");
                      setShowMenu(false);
                      break;
                  }
                }}
              />
            </InputContainer>
          )}
        </Reference>
        <Popper placement={"top-start"}>
          {({ ref, style, placement }) => {
            return (
              showMenu && (
                <div
                  key={input}
                  ref={ref}
                  style={{ ...style, minWidth: 320 }}
                  data-placement={placement}
                >
                  <TagMenu
                    actions={menuActions}
                    performAction={performAction}
                    selectedIndex={selectedIndex}
                  />
                </div>
              )
            );
          }}
        </Popper>
      </div>
    </Manager>
  );
};
