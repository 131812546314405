import { AppDatabase } from "../Database";
import { useCallback, useMemo } from "react";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TaggedNote, TaggedNoteAction } from "./types";
import { initialTaggedNote, updateTaggedNote } from "./reducer";

export type TaggedNoteStore = {
  addTaggedNoteAction: (action: TaggedNoteAction) => Promise<TaggedNote | null>;
  observeNoteById: (noteId: string) => Observable<TaggedNote | null>;
  observeNotes: (filter: any, limit?: number) => Observable<TaggedNote[]>;
  observeNextDueNotes: (now: Date) => Observable<TaggedNote[]>;
  fetchNoteById: (noteId: string) => Promise<TaggedNote | null>;
};

const updateTaggedNoteFromAction = (
  db: AppDatabase,
  action: TaggedNoteAction
): Promise<TaggedNote | null> => {
  switch (action.type) {
    case "SAVE_TAG":
      return db.tagged_notes
        .find({
          tagIds: {
            $elemMatch: { $eq: action.tagId }
          }
        })
        .exec()
        .then(docs => {
          if (docs) {
            const taggedNotes = docs.map(doc => doc.toTaggedNote());
            return Promise.all(
              taggedNotes.map(taggedNote => {
                const newTaggedNote = updateTaggedNote(taggedNote, action);
                return db.tagged_notes.atomicUpsert(
                  db.tagged_notes.fromTaggedNote(newTaggedNote)
                );
              })
            ).then(() => null);
          } else {
            return null;
          }
        });
    case "DELETE_NOTE":
    case "COMPLETE_EXERCISE":
    case "CONFIGURE_NOTE":
    case "SAVE_TAGGED_NOTE":
      return db.tagged_notes
        .findOne()
        .where("noteId")
        .eq(action.noteId)
        .exec()
        .then(doc => {
          if (doc) {
            const taggedNote = updateTaggedNote(doc.toTaggedNote(), action);
            if (taggedNote.isDeleted) {
              return db.tagged_notes
                .find()
                .where("noteId")
                .eq(action.noteId)
                .remove()
                .then(() => null);
            } else {
              return db.tagged_notes
                .atomicUpsert(db.tagged_notes.fromTaggedNote(taggedNote))
                .then(() => taggedNote);
            }
          } else if (action.type === "SAVE_TAGGED_NOTE") {
            const taggedNote = initialTaggedNote(action);
            return db.tagged_notes
              .atomicUpsert(db.tagged_notes.fromTaggedNote(taggedNote))
              .then(() => taggedNote);
          } else {
            return null;
          }
        });
  }
};

export const useTaggedNoteStore = (db: AppDatabase): TaggedNoteStore => {
  const fetchNoteById = useCallback(
    async (noteId: string): Promise<TaggedNote | null> => {
      return db.tagged_notes
        .findOne()
        .where("noteId")
        .eq(noteId)
        .exec()
        .then(doc => doc && doc.toTaggedNote());
    },
    [db]
  );
  const addTaggedNoteAction = useCallback(
    async (action: TaggedNoteAction): Promise<TaggedNote | null> => {
      return updateTaggedNoteFromAction(db, action);
    },
    [db]
  );
  const observeNextDueNotes = useCallback(
    (now: Date) => {
      return db.tagged_notes
        .find()
        .sort({ upcomingTimestamp: "asc" })
        .where("upcomingTimestamp")
        .lte(now.getTime())
        .$.pipe(map(notes => notes && notes.map(note => note.toTaggedNote())));
    },
    [db]
  );

  const observeNoteById = useCallback(
    (noteId: string) => {
      return db.tagged_notes
        .findOne()
        .where("noteId")
        .eq(noteId)
        .$.pipe(map(doc => doc && doc.toTaggedNote()));
    },
    [db]
  );

  const observeNotes = useCallback(
    (filter: any, limit?: number) => {
      return db.tagged_notes
        .find(filter)
        .limit(limit || null)
        .sort({ createdAt: "desc" })
        .$.pipe(map(docs => docs.map(doc => doc.toTaggedNote())));
    },
    [db]
  );
  return useMemo(
    () => ({
      fetchNoteById,
      addTaggedNoteAction,
      observeNextDueNotes,
      observeNoteById,
      observeNotes
    }),
    [
      addTaggedNoteAction,
      fetchNoteById,
      observeNextDueNotes,
      observeNoteById,
      observeNotes
    ]
  );
};
