import React from "react";
import "./App.css";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "@emotion/styled";
import { useLocalStorage } from "./LocalStorage";
import {
  ForgotPasswordPage,
  LoginPage,
  LogoutScreen,
  PasswordResetPage
} from "./BrightTheme/pages/login";
import { Homepage } from "./BrightTheme/pages/home";
import * as queryString from "querystring";
import { NotesPage } from "./BrightTheme/pages/notes";
import { SignupPage } from "./BrightTheme/pages/signup";
import { SettingsPage } from "./BrightTheme/pages/settings";
import {
  ExercisePageDemo,
  ExercisesByIdPage
} from "./BrightTheme/pages/exercises";
import { Page404 } from "./BrightTheme/pages/404";
import { AppDatabase, createDb, DATABASE_NAME } from "./Database";
import { Loader } from "./BrightTheme/loading";
import { CenteredPage } from "./BrightTheme/page";
import { SecondaryButton } from "./BrightTheme/button";
import { removeDatabase } from "rxdb";
import { NoteDetailsPage } from "./BrightTheme/pages/noteDetails";
import { RevisionPage } from "./BrightTheme/pages/revision";
import { asContent, LCE } from "./LCE";
import { ErrorMessage } from "./BrightTheme/text";
import { useStore } from "./Store";
import { StoreDebuggerPage } from "./BrightTheme/pages/storeDebugger";

const AppWrapper = styled.div`
  width: 100%;
  max-width: 960px;
`;
const AppBackground = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  height: 90vh;
  width: 100%;
  align-self: center;
`;

export type Session = {
  token: string;
  email: string;
};

const AppWithDb = ({ db, resync }: { db: AppDatabase; resync: () => void }) => {
  const [sessionStr, setSessionStr] = useLocalStorage("session", null);
  const session = sessionStr ? (JSON.parse(sessionStr) as Session) : null;
  const setSession = (session: Session | null) => {
    session ? setSessionStr(JSON.stringify(session)) : setSessionStr(null);
  };
  const store = useStore(db, session && session.token);
  // const store = useStore(db, null);
  if (session === null) {
    return (
      <Switch>
        <Route
          path={"/signup"}
          exact
          render={() => <SignupPage sessionCallback={setSession} />}
        />
        <Route
          path={"/login"}
          exact
          render={() => <LoginPage sessionCallback={setSession} />}
        />
        <Route
          path={"/forgotPassword"}
          exact
          render={() => <ForgotPasswordPage />}
        />
        <Route
          path={"/passwordReset"}
          exact
          render={({ location }) => {
            const queryParams = queryString.parse(location.search.slice(1));
            return (
              <PasswordResetPage
                loginToken={
                  queryParams.token ? queryParams.token.toString() : ""
                }
              />
            );
          }}
        />
        <Redirect exact to={"/login"} />
      </Switch>
    );
  } else {
    return (
      <AppBackground>
        <AppWrapper>
          <Switch>
            <Route
              path={"/exercise-demo"}
              exact
              render={() => <ExercisePageDemo store={store} />}
            />
            <Route
              path={"/exercises/:id"}
              exact
              render={({ match }) => {
                return <ExercisesByIdPage id={match.params.id} store={store} />;
              }}
            />
            <Route
              path={"/notes/:id"}
              exact
              render={({ match }) => {
                return (
                  <NoteDetailsPage noteId={match.params.id} store={store} />
                );
              }}
            />
            <Route
              path={"/notes"}
              exact
              render={({ location }) => {
                const queryParams = queryString.parse(location.search.slice(1));
                return (
                  <NotesPage
                    tags={
                      typeof queryParams.tags === "string"
                        ? queryParams.tags.split(",")
                        : []
                    }
                    store={store}
                  />
                );
              }}
            />
            <Route
              path={"/logout"}
              exact
              render={() => (
                <LogoutScreen sessionCallback={() => setSession(null)} />
              )}
            />
            <Route
              path={"/revision"}
              exact
              render={() => <RevisionPage store={store} />}
            />
            <Route
              path={"/settings"}
              exact
              render={() => (
                <SettingsPage
                  email={session.email}
                  store={store}
                  resyncDb={resync}
                />
              )}
            />
            <Route
              path={"/signup"}
              exact
              render={() => <Redirect to={"/"} />}
            />
            <Route
              path={"/passwordReset"}
              exact
              render={({ location }) => {
                const queryParams = queryString.parse(location.search.slice(1));
                return (
                  <PasswordResetPage
                    loginToken={
                      queryParams.token ? queryParams.token.toString() : ""
                    }
                  />
                );
              }}
            />
            <Route
              path={"/debug"}
              exact
              render={() => <StoreDebuggerPage store={store} />}
            />
            <Route path={"/login"} exact render={() => <Redirect to={"/"} />} />
            <Route path={"/"} exact render={() => <Homepage store={store} />} />
            <Route render={() => <Page404 />} />
          </Switch>
        </AppWrapper>
      </AppBackground>
    );
  }
};

type AppProps = {
  dbAdapter: "idb" | "memory";
};

class App extends React.Component<AppProps> {
  state: LCE<AppDatabase> = {
    type: "Loading"
  };
  async componentDidMount() {
    const db = await createDb(this.props.dbAdapter);
    this.setState(asContent(db));
  }

  async componentWillUnmount() {
    if (this.state.type === "Content") {
      await this.state.content.destroy();
    }
  }
  render() {
    const dbState = this.state;
    const resync = () => {
      if (dbState.type === "Content") {
        const db = dbState.content;
        this.setState({ type: "Loading" });
        db.remove().then(() => window.location.reload());
      } else {
        removeDatabase(DATABASE_NAME, "idb").then(() =>
          window.location.reload()
        );
      }
    };

    if (dbState.type === "Loading") {
      return (
        <CenteredPage>
          <Loader />
          <SecondaryButton onClick={resync}>Resync</SecondaryButton>
        </CenteredPage>
      );
    } else if (dbState.type === "Error") {
      return (
        <CenteredPage>
          <ErrorMessage>{dbState.error}</ErrorMessage>
          <SecondaryButton onClick={resync}>Resync</SecondaryButton>
        </CenteredPage>
      );
    } else {
      return <AppWithDb db={dbState.content} resync={resync} />;
    }
  }
}

export default App;
