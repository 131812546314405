import React, { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { PageWithNav, Section } from "../page";
import { Title } from "../text";
import { Loader } from "../loading";
import { asContent, LCE } from "../../LCE";
import { SecondaryButton } from "../button";
import { PageItem } from "../layout";
import { Store } from "../../Store";
import { NoteEditor } from "../components/noteEditor";
import { NoteTable } from "../components/noteTable";
import { useAllTagsSubscription } from "../hooks/useAllTagsSubscription";
import { TaggedNote } from "../../TaggedNote/types";
import { emptyTaggedNote } from "../../TaggedNote/reducer";

export const Homepage = ({ store }: { store: Store }) => {
  const history = useHistory();
  const now = useMemo(() => new Date(), []);

  const [pageState, setPageState] = useState<LCE<TaggedNote[]>>({
    type: "Loading"
  });

  useEffect(() => {
    const sub = store
      .observeTaggedNotes(null, 5)
      .subscribe(taggedNotes => setPageState(asContent(taggedNotes)));
    return () => sub.unsubscribe();
  }, [store]);
  const allTags = useAllTagsSubscription(store);
  return (
    <PageWithNav navLocation={"Home"}>
      <PageItem>
        <Title>New Note</Title>
      </PageItem>
      <NoteEditor
        initialNote={emptyTaggedNote()}
        store={store}
        initialTags={allTags || []}
      />
      <Section>
        <PageItem>
          <Title>Recently added</Title>
        </PageItem>
        {pageState.type === "Loading" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: 16
            }}
          >
            <Loader />
          </div>
        ) : pageState.type === "Error" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            An error has occurred!
          </div>
        ) : (
          <>
            <NoteTable notes={pageState.content} now={now} store={store} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SecondaryButton onClick={() => history.push("/notes")}>
                Show all notes
              </SecondaryButton>
            </div>
          </>
        )}
      </Section>
    </PageWithNav>
  );
};
