import styled from "@emotion/styled";
import { blue700, grey600, grey700, red700 } from "./colors";

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  color: ${blue700};
  margin-block-start: 0.33rem;
  margin-block-end: 0.33rem;
`;
export const SubTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: ${grey700};
  margin-block-start: 0.33rem;
  margin-block-end: 0.33rem;
`;

export const Paragraph = styled.p`
  color: ${grey700};
  font-size: 14px;
  margin-block-start: 0;
  margin-block-end: 0;
  alignment-baseline: baseline;
`;
export const SmallText = styled.p`
  color: ${grey600};
  font-size: 12px;
  margin-block-start: 0;
  margin-block-end: 0;
  alignment-baseline: baseline;
`;

export const ErrorMessage = styled(Paragraph)`
  color: ${red700};
`;
