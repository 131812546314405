import { useEffect, useState } from "react";

export const useNow = (
  refreshIntervalSeconds: number = 60
): [Date, () => void] => {
  const [now, setNow] = useState(new Date());
  const refreshNow = () => setNow(new Date());
  useEffect(() => {
    const i = setInterval(
      () => setNow(new Date()),
      1000 * refreshIntervalSeconds
    );
    return () => {
      clearInterval(i);
    };
  }, [refreshIntervalSeconds]);
  return [now, refreshNow];
};
