import styled from "@emotion/styled";
import { grey800 } from "./colors";

export const LeftRightLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
`;
export const PageItem = styled.div`
  padding: 0 0 0.5rem;
  box-sizing: border-box;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  fill: ${grey800};
  height: 24px;
  button {
    margin: 0 4px;
  }
  a {
    margin: 0 4px;
    text-decoration: none;
    padding: 0;
  }
`;
