import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styled from "@emotion/styled";
import produce from "immer";
import { PageWithNav } from "../page";
import { SmallText, Title } from "../text";
import { Column } from "./summary";
import { Loading } from "../../LCE";
import { Loader } from "../loading";
import { ButtonRow, Centered, PageItem } from "../layout";
import { useNow } from "../hooks/useNow";
import { PrimaryButton, SecondaryButton } from "../button";
import { Footer } from "./notes";
import { grey600, grey700 } from "../colors";
import { formatDatetimeDuration } from "../../datetime";
import { DateTime, Duration } from "luxon";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { useHistory } from "react-router";
import { usePaginationLimits } from "../hooks/usePaginationLimits";
import {
  NegativeActionCallout,
  PositiveActionCallout
} from "../components/actionCallout";
import {
  exerciseStateIsAnswerable,
  exerciseStateLastAnsweredAt
} from "../../Exercise/reducer";
import { ExerciseAction, ExerciseState } from "../../Exercise/types";
import { ExerciseComponent, ExerciseWrapper } from "../components/exerciseComponent";
import { ExerciseNote, Store } from "../../Store";
import { NoteContentRenderer } from "../components/noteContentRenderer";
import { first } from "rxjs/operators";

const ActiveQuestionWrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

type InfiniteExercisePageState = {
  type: "InfiniteExercisePageState";
  exerciseState: ExerciseState;
  remainingDueCount: number;
};

type RevisionPageState = {
  type: "RevisionPageState";
  remainingDueCount: number;
};

type ExercisePageState =
  | InfiniteExercisePageState
  | RevisionPageState
  | Loading;

const StartExerciseComponent = ({
  remainingDueCount,
  actionCallback
}: {
  remainingDueCount: number;
  actionCallback: () => void;
}) => {
  const btn = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (btn.current) {
      btn.current.focus();
    }
  }, [btn]);
  return (
    <PageItem>
      <NegativeActionCallout>
        <Column>
          <strong>Time for revising!</strong>
          <p>
            You've got <strong>{remainingDueCount}</strong> note
            {remainingDueCount === 1 ? "" : "s"} due
          </p>
        </Column>
        <PrimaryButton onClick={actionCallback} ref={btn}>
          Start
        </PrimaryButton>
      </NegativeActionCallout>
    </PageItem>
  );
};

const AllCaughtUpComponent = ({
  actionCallback
}: {
  actionCallback: () => void;
}) => {
  const btn = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (btn.current) {
      btn.current.focus();
    }
  }, [btn]);
  return (
    <PageItem>
      <PositiveActionCallout>
        <Column>
          <strong>Congratulations!</strong>
          <p>You are all caught up</p>
        </Column>
        <PrimaryButton onClick={actionCallback} ref={btn}>
          Notes
        </PrimaryButton>
      </PositiveActionCallout>
    </PageItem>
  );
};
export const RevisionPage = ({ store }: { store: Store }) => {
  const [now, refreshNow] = useNow();
  const history = useHistory();
  const [pageState, setPageState] = useState<ExercisePageState>({
    type: "Loading"
  });
  const goToNextExercise = useCallback(
    (timeoutSeconds: number = 2) => {
      store.taggedNotes
        .observeNextDueNotes(now)
        .pipe(first())
        .toPromise()
        .then(res => res.length)
        .then(count => {
          const pageStatePromise: Promise<ExercisePageState> =
            count > 0
              ? store.launchNextDueExercise(new Date()).then((ex: ExerciseNote | null) => {
                  if (ex) {
                    return {
                      type: "InfiniteExercisePageState",
                      exerciseState: ex.exerciseState,
                      remainingDueCount: count
                    };
                  } else {
                    return {
                      type: "RevisionPageState",
                      remainingDueCount: count
                    };
                  }
                })
              : Promise.resolve({
                  type: "RevisionPageState",
                  remainingDueCount: count
                });
          pageStatePromise.then(s =>
            setTimeout(() => {
              setPageState(s);
              refreshNow();
            }, timeoutSeconds * 1000)
          );
        });
    },
    [now, refreshNow, store]
  );
  useEffect(() => {
    if (pageState.type !== "InfiniteExercisePageState") {
      const sub = store.taggedNotes.observeNextDueNotes(now).subscribe(res => {
        const pageState: RevisionPageState = {
          remainingDueCount: res.length,
          type: "RevisionPageState"
        };
        setPageState(pageState);
      });
      return () => sub.unsubscribe();
    }
  }, [now, pageState.type, store.taggedNotes]);
  switch (pageState.type) {
    case "Loading":
      return (
        <PageWithNav navLocation={"Revision"}>
          <Centered>
            <Loader />
          </Centered>
        </PageWithNav>
      );
    case "RevisionPageState":
      const { remainingDueCount } = pageState;
      if (remainingDueCount > 0) {
        return (
          <PageWithNav navLocation={"Revision"}>
            <StartExerciseComponent
              remainingDueCount={pageState.remainingDueCount}
              actionCallback={() => goToNextExercise(0)}
            />
            <PastExercises store={store} now={now} />
          </PageWithNav>
        );
      } else {
        return (
          <PageWithNav navLocation={"Revision"}>
            <AllCaughtUpComponent
              actionCallback={() => history.push("/notes")}
            />
            <PastExercises store={store} now={now} />
          </PageWithNav>
        );
      }
    case "InfiniteExercisePageState":
      const updateExerciseAction = (action: ExerciseAction) => {
        store.enqueueAction(action).then(exerciseNote => {
          if (exerciseNote) {
            const { exerciseState } = exerciseNote;
            setPageState(
              produce(pageState, s => {
                s.exerciseState = exerciseState;
              })
            );
            if (!exerciseStateIsAnswerable(exerciseState)) {
              goToNextExercise(1.5);
            }
          }
        });
      };

      return (
        <PageWithNav navLocation={"Revision"}>
          <ActiveQuestionWrapper>
            <Title>Notes Due ({pageState.remainingDueCount})</Title>
            <ExerciseComponent
              exerciseState={pageState.exerciseState}
              setExerciseAction={updateExerciseAction}
            />
          </ActiveQuestionWrapper>
        </PageWithNav>
      );
  }
};

const PastExercises = ({ store, now }: { store: Store; now: Date }) => {
  const nowDateTime = useMemo(() => DateTime.fromJSDate(now), [now]);
  const history = useHistory();
  const [exerciseNotes, setExerciseNotes] = useState<ExerciseNote[]>([]);
  const { renderLimit, setRenderLimit, loadLimit } = usePaginationLimits(
    10,
    50
  );
  const totalCount = exerciseNotes.length;
  const slicedExerciseNotes = exerciseNotes.slice(0, renderLimit);
  useEffect(() => {
    const sub = store.exercises
      .observeActiveExercises(
        nowDateTime.minus(Duration.fromObject({ days: 1 })).toJSDate(),
        loadLimit
      )
      .subscribe(exs => {
        Promise.all(exs.map(ex => store.fetchExerciseNotesByExercise(ex)))
          .then(
            exs =>
              exs.filter(
                ex => ex && !exerciseStateIsAnswerable(ex.exerciseState)
              ) as ExerciseNote[]
          )
          .then(exs => setExerciseNotes(exs));
      });
    return () => sub.unsubscribe();
  }, [store, nowDateTime, loadLimit]);
  return (
    <>
      <Title>Past Day Activity</Title>
      {slicedExerciseNotes.length === 0 ? (
        <p>No activity in the past 24 hours</p>
      ) : null}
      {slicedExerciseNotes.map(({ exerciseState, noteState }, idx) => {
        const answeredAt = exerciseStateLastAnsweredAt(exerciseState);
        return (
          <PageItem key={idx}>
            <ExerciseWrapper>
              <NoteContentRenderer
                noteContents={exerciseState.note.content}
                clozeDisplayStatusMap={exerciseState.clozeStatuses}
              />
              <Footer style={{ marginTop: "1rem" }}>
                {answeredAt && (
                  <SmallText>
                    <span style={{ fontWeight: 600, color: grey700 }}>
                      {`Answered ${formatDatetimeDuration(
                        DateTime.fromJSDate(answeredAt),
                        nowDateTime
                      )}  · `}
                    </span>
                    Due{" "}
                    {formatDatetimeDuration(
                      DateTime.fromJSDate(noteState.upcomingTimestamp),
                      nowDateTime
                    )}
                  </SmallText>
                )}
                <ButtonRow>
                  <a
                    href={`/notes/${exerciseState.note.noteId}`}
                    onClick={evt => {
                      evt.preventDefault();
                      history.push(`/notes/${exerciseState.note.noteId}`);
                    }}
                  >
                    <SettingsIcon style={{ fill: grey600 }} />
                  </a>
                </ButtonRow>
              </Footer>
            </ExerciseWrapper>
          </PageItem>
        );
      })}
      {totalCount !== null && totalCount > slicedExerciseNotes.length && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SecondaryButton onClick={() => setRenderLimit(l => l + 10)}>
            Show more
          </SecondaryButton>
        </div>
      )}
    </>
  );
};
