import * as t from "io-ts";
import { DateCodec, DurationCodec } from "../IOTypes";

// ---------------------------
// Note Content Blocks
// ---------------------------
// The note content block represents a block of arbitrary content
//

const MarkdownBlock = t.type({
  type: t.literal("MARKDOWN_BLOCK"),
  content: t.string
});

type MarkdownBlock = t.TypeOf<typeof MarkdownBlock>;

export const NoteContentBlock = MarkdownBlock;
export type NoteContentBlock = MarkdownBlock; // This will include image occlusion soon

// ---------------------------
// Note Actions
// ---------------------------
// This represents all the actions that can be applied to note,
// including creating the note itself.
//

export const SaveNote = t.type({
  actionId: t.string,
  type: t.literal("SAVE_NOTE"),
  noteId: t.string,
  createdAt: DateCodec,
  content: t.array(NoteContentBlock),
  tagIds: t.array(t.string)
});
export type SaveNote = t.TypeOf<typeof SaveNote>;

export const ConfigureNote = t.type({
  actionId: t.string,
  type: t.literal("CONFIGURE_NOTE"),
  noteId: t.string,
  createdAt: DateCodec,

  // If answerInOrder is true, the answers will be blanked out in order of appearance
  // If answerInOrder is false, the answers will be blanked out in random order
  answerInOrder: t.boolean,

  // If hideOne is true, only one answer will be hidden at a time.
  // If hideOne is false, all answers will be hidden until they are answered (correctly or otherwise)
  hideOne: t.boolean,

  // If foo is true, only the first hidden blank can be answered
  // If foo is false, any hidden blank can be answered
  onlyFirstBlankAnswerable: t.boolean
});
export type ConfigureNote = t.TypeOf<typeof ConfigureNote>;

export const DeleteNote = t.type({
  actionId: t.string,
  type: t.literal("DELETE_NOTE"),
  noteId: t.string,
  createdAt: DateCodec
});
export type DeleteNote = t.TypeOf<typeof DeleteNote>;

export const UpdateNoteInterval = t.type({
  actionId: t.string,
  type: t.literal("UPDATE_NOTE_INTERVAL"),
  noteId: t.string,
  createdAt: DateCodec,
  addedDuration: DurationCodec,
  upcomingTimestamp: DateCodec,
  exerciseId: t.string
});
export type UpdateNoteInterval = t.TypeOf<typeof UpdateNoteInterval>;

export const NoteAction = t.union([
  SaveNote,
  ConfigureNote,
  DeleteNote,
  UpdateNoteInterval
]);
export type NoteAction = t.TypeOf<typeof NoteAction>;

// ---------------------------
// Note State
// ---------------------------
// This represents the current state of a given note.
//

export const NoteState = t.type({
  noteId: t.string,
  createdAt: DateCodec,
  lastUpdatedAt: DateCodec,
  content: t.array(NoteContentBlock),
  tagIds: t.array(t.string),
  currentDuration: DurationCodec,
  upcomingTimestamp: DateCodec,
  isDeleted: t.boolean,
  answerInOrder: t.boolean,
  hideOne: t.boolean,
  onlyFirstBlankAnswerable: t.boolean
});

export type NoteState = t.TypeOf<typeof NoteState>;
