import styled from "@emotion/styled";
import { blue600, grey400, grey500, grey700, white } from "./colors";

export const PrimaryButton = styled.button`
  :active {
    box-shadow: 0 1px 1px ${grey400};
    transform: translateY(1px);

    outline: 0;
  }
  :disabled {
    color: ${white};
    background: ${blue600};
  }
  :hover:disabled {
    background: ${blue600};
  }
  :hover {
    background: ${blue600};
  }

  :focus {
    background: ${blue600};
    color: ${white};
  }
  background: ${blue600};
  border-radius: 4px;

  font-style: normal;
  font-weight: bold;
  line-height: 10px;

  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;

  min-height: 32px;
  min-width: 96px;
  padding: 0 16px;
  color: ${white};
  box-shadow: 0 2px 2px ${grey400};
  width: fit-content;
`;
export const SecondaryButton = styled.button`
  width: auto;
  text-decoration: underline;
  text-transform: uppercase;
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 10px;
  min-height: 32px;

  //letter-spacing: 0.04rem;
  color: ${grey700};

  :focus {
    color: ${grey700};
  }
  :active {
    color: ${grey500};
  }
  :disabled {
    color: ${grey700};
  }
`;
