import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Store } from "../../Store";
import { v4 as uuid } from "uuid";
import { NoteContentBlock } from "../../Note/types";
import { NoteContentRenderer } from "./noteContentRenderer";
import { NoteCard } from "./noteCard";
import { PrimaryButton, SecondaryButton } from "../button";
import { red600, white } from "../colors";
import { Title } from "../text";
import { NoteEditor } from "./noteEditor";
import { Modal } from "./modal";
import { useAllTagsSubscription } from "../hooks/useAllTagsSubscription";
import { highlightAllClozes } from "../../Note/clozeStatuses";
import { TaggedNote } from "../../TaggedNote/types";

const DialogContainer = styled.div`
  padding: 16px;
`;

const ButtonRow = styled.div`
  padding: 32px 0 0.33rem;
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  width: 100%;
  button {
    margin-right: 16px;
  }
`;

const DangerButton = styled(PrimaryButton)`
  :disabled {
    color: ${white};
    background: ${red600};
  }
  :hover:disabled {
    background: ${red600};
  }
  :hover {
    background: ${red600};
  }

  :focus {
    background: ${red600};
    color: ${white};
  }
  background: ${red600};
`;
const DeleteConfirmationDialog = ({
  title,
  content,
  onConfirm,
  onClose
}: {
  title: string;
  content: NoteContentBlock[];
  onConfirm: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal onDismiss={onClose}>
      <DialogContainer>
        <Title>{title}</Title>
        <NoteContentRenderer
          noteContents={content}
          clozeDisplayStatusMap={highlightAllClozes(content)}
        />
        <ButtonRow>
          <DangerButton onClick={onConfirm}>Delete Note</DangerButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </ButtonRow>
      </DialogContainer>
    </Modal>
  );
};

export const NoteTable = React.memo(
  ({ notes, now, store }: { notes: TaggedNote[]; now: Date; store: Store }) => {
    const deleteNote = useCallback(
      (noteId: string) => {
        store.enqueueAction({
          type: "DELETE_NOTE",
          actionId: uuid(),
          createdAt: new Date(),
          noteId
        });
      },
      [store]
    );
    const [deletingNote, setDeletingNote] = useState<TaggedNote | null>(null);
    const [editing, setEditing] = useState<string | null>(null);
    const allTags = useAllTagsSubscription(store);
    return (
      <>
        {deletingNote && (
          <DeleteConfirmationDialog
            title={"Delete this note?"}
            content={deletingNote.content}
            onConfirm={() => {
              deleteNote(deletingNote.noteId);
              setDeletingNote(null);
            }}
            onClose={() => setDeletingNote(null)}
          />
        )}
        {notes.map(taggedNote => {
          return allTags !== null && taggedNote.noteId === editing ? (
            <NoteEditor
              initialNote={taggedNote}
              initialTags={allTags}
              saveHook={() => setEditing(null)}
              cancelCallback={() => {
                setEditing(null);
              }}
              key={taggedNote.noteId + "editing"}
              store={store}
            />
          ) : (
            <NoteCard
              taggedNote={taggedNote}
              now={now}
              setDeletingNote={setDeletingNote}
              setEditing={setEditing}
              key={taggedNote.noteId}
            />
          );
        })}
      </>
    );
  }
);
