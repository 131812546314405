import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Redirect } from "react-router";
import { CenteredPage } from "../page";
import { PrimaryButton, SecondaryButton } from "../button";
import { grey800, red800, white } from "../colors";
import { Session } from "../../App";

type LoginResponse = {
  id: string;
  email: string;
  token: string;
};

const LoginCard = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${white};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  div {
    margin-bottom: 4px;
  }
`;

const TextField = styled.input`
  box-sizing: border-box;
  padding: 8px 4px;
  width: 100%;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;
  margin-block-start: 4px;
  margin-block-end: 4px;
  color: ${grey800};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const ErrorStatus = styled.div`
  min-height: 16px;
  color: ${red800};
`;

export const LoginPage = ({
  sessionCallback
}: {
  sessionCallback: (session: Session) => void;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const attemptLogin = (email: string, password: string) => {
    setLoading(true);
    const payload = { email, password };
    fetch("https://auth.blanknot.es/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    })
      .then(resp => {
        setLoading(false);
        if (!resp.ok) {
          setError("Login attempt was not successful");
          throw new Error(resp.statusText);
        } else {
          return resp.json() as Promise<LoginResponse>;
        }
      })
      .then(sessionCallback)
      .catch(err => console.log(err));
  };
  return (
    <CenteredPage>
      <LoginCard>
        <Form
          onSubmit={evt => {
            evt.preventDefault();
            attemptLogin(email, password);
          }}
        >
          <TextField
            id="email"
            placeholder="Email address"
            type="email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
          />
          <TextField
            id="standard-password-input"
            placeholder="Password"
            type="password"
            autoComplete="current-password"
            onChange={evt => setPassword(evt.target.value)}
            style={{ minWidth: 300 }}
          />
          <ErrorStatus>{error}</ErrorStatus>
          <PrimaryButton type="submit" disabled={loading}>
            Login
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              window.location.href = "/forgotPassword";
            }}
          >
            Forgot password
          </SecondaryButton>
        </Form>
      </LoginCard>
    </CenteredPage>
  );
};

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const triggerPasswordReset = () => {
    setLoading(true);
    const payload = { email };
    fetch("https://auth.blanknot.es/triggerPasswordReset", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    })
      .then(resp => {
        setLoading(false);
        if (!resp.ok) {
          setError("Password reset attempt was not successful");
          throw new Error(resp.statusText);
        } else {
          return resp.json() as Promise<LoginResponse>;
        }
      })
      .then(() => {
        setShowSuccessMessage(true);
      })
      .catch(err => console.log(err));
  };
  if (!showSuccessMessage) {
    return (
      <CenteredPage>
        <LoginCard>
          <Form
            onSubmit={evt => {
              evt.preventDefault();
              triggerPasswordReset();
            }}
          >
            <TextField
              id="email"
              placeholder="Email address"
              type="email"
              value={email}
              onChange={evt => setEmail(evt.target.value)}
            />
            <ErrorStatus>{error}</ErrorStatus>
            <PrimaryButton type="submit" disabled={loading}>
              Reset password
            </PrimaryButton>
          </Form>
        </LoginCard>
      </CenteredPage>
    );
  } else {
    return (
      <CenteredPage>
        <LoginCard>
          A password reset email has been sent to <strong>{email}</strong>.
          Please follow that link to reset your password.
        </LoginCard>
      </CenteredPage>
    );
  }
};

export const PasswordResetPage = ({ loginToken }: { loginToken: string }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const resetPassword = (token: string, password: string) => {
    setLoading(true);
    const payload = { token, password };
    fetch("https://auth.blanknot.es/resetPassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    })
      .then(resp => {
        setLoading(false);
        if (!resp.ok) {
          setError("Password reset attempt was not successful");
          throw new Error(resp.statusText);
        } else {
          return resp.json() as Promise<LoginResponse>;
        }
      })
      .then(() => {
        window.location.href = "/";
      })
      .catch(err => console.log(err));
  };
  return (
    <CenteredPage>
      <LoginCard>
        <Form
          onSubmit={evt => {
            evt.preventDefault();
            if (password === confirmPassword) {
              resetPassword(loginToken, password);
            } else {
              setError("Passwords do not match");
            }
          }}
        >
          <TextField
            id="standard-password-input"
            placeholder="New password"
            type="password"
            value={password}
            onChange={evt => setPassword(evt.target.value)}
          />
          <TextField
            id="standard-password-input-confirm"
            placeholder="Confirm password"
            type="password"
            value={confirmPassword}
            onChange={evt => setConfirmPassword(evt.target.value)}
            style={{ minWidth: 300 }}
          />
          <ErrorStatus>{error}</ErrorStatus>
          <PrimaryButton type="submit" disabled={loading}>
            Update Password
          </PrimaryButton>
        </Form>
      </LoginCard>
    </CenteredPage>
  );
};
export const LogoutScreen = ({
  sessionCallback
}: {
  sessionCallback: (session: Session | null) => void;
}) => {
  useEffect(() => sessionCallback(null));
  return <Redirect to={"/"} />;
};
