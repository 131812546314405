import React, { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { grey600, grey700 } from "../colors";

import { useHistory } from "react-router";
import { PrimaryButton } from "../button";
import { SmallText, Title } from "../text";
import { IntervalOld } from "../../interval";
import { formatDatetimeDuration } from "../../datetime";
import { DateTime } from "luxon";
import { Footer } from "./notes";

import { ButtonRow } from "../layout";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import {
  NegativeActionCallout,
  PositiveActionCallout
} from "../components/actionCallout";
import { ExerciseState } from "../../Exercise/types";
import {
  exerciseStateIsCorrect,
  exerciseStateLastAnsweredAt
} from "../../Exercise/reducer";
import { ExerciseWrapper } from "../components/exerciseComponent";
import {NoteContentRenderer} from "../components/noteContentRenderer";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
`;

//
const PageItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 2px;
`;

type Stats = {
  correctCount: number;
  totalCount: number;
};

const CongratulationsComponent = ({ correctCount, totalCount }: Stats) => {
  const btn = useRef<HTMLButtonElement | null>(null);
  const history = useHistory();
  const navTo = useCallback((path: string) => () => history.push(path), [
    history
  ]);
  useEffect(() => {
    if (btn.current) {
      btn.current.focus();
    }
  }, [btn]);
  return (
    <PageItem>
      <PositiveActionCallout>
        <Column>
          <strong>Congratulations!</strong>
          <p>
            You've got all{" "}
            <strong>
              {correctCount}/{totalCount}
            </strong>{" "}
            correct!
          </p>
        </Column>
        <PrimaryButton onClick={navTo("/revision")} ref={btn}>
          Go back
        </PrimaryButton>
      </PositiveActionCallout>
    </PageItem>
  );
};

const KeepRevisingComponent = ({ correctCount, totalCount }: Stats) => {
  const btn = useRef<HTMLButtonElement | null>(null);
  const history = useHistory();
  const navTo = useCallback((path: string) => () => history.push(path), [
    history
  ]);
  useEffect(() => {
    if (btn.current) {
      btn.current.focus();
    }
  }, [btn]);
  return (
    <NegativeActionCallout>
      <Column>
        <strong>Keep trying!</strong>
        <p>
          You've got{" "}
          <strong>
            {correctCount}/{totalCount}
          </strong>{" "}
          correct
        </p>
      </Column>
      <PrimaryButton onClick={navTo("/revision")} ref={btn}>
        Try again
      </PrimaryButton>
    </NegativeActionCallout>
  );
};

export type ExerciseInterval = {
  exerciseState: ExerciseState;
  interval: IntervalOld;
};
export const SummaryComponent = ({
  exerciseIntervals
}: {
  exerciseIntervals: ExerciseInterval[];
}) => {
  const exercises = exerciseIntervals.map(x => x.exerciseState);
  const correctAnswerCount = exercises.filter(
    x => exerciseStateIsCorrect(x) !== "INCORRECT"
  ).length;
  const totalCount = exercises.length;
  const allCorrect = correctAnswerCount === totalCount;
  const now = new Date();
  const nowDateTime = DateTime.fromJSDate(now);
  const history = useHistory();
  return (
    <>
      {allCorrect ? (
        <CongratulationsComponent
          correctCount={correctAnswerCount}
          totalCount={totalCount}
        />
      ) : (
        <KeepRevisingComponent
          correctCount={correctAnswerCount}
          totalCount={totalCount}
        />
      )}
      {exerciseIntervals.map(({ exerciseState, interval }, idx) => {
        const answeredAt = exerciseStateLastAnsweredAt(exerciseState);
        const noteId = exerciseState.note.noteId;
        return (
          <PageItem key={idx}>
            <Title>Question {idx + 1}</Title>
            <ExerciseWrapper>
              <NoteContentRenderer
                noteContents={exerciseState.note.content}
                clozeDisplayStatusMap={exerciseState.clozeStatuses}
              />
              <Footer style={{ marginTop: "1rem" }}>
                {answeredAt && (
                  <SmallText>
                    {interval && (
                      <span style={{ fontWeight: 600, color: grey700 }}>
                        {`Due ${formatDatetimeDuration(
                          DateTime.fromJSDate(interval.upcomingTimestamp),
                          nowDateTime
                        )}`}
                      </span>
                    )}
                  </SmallText>
                )}
                <ButtonRow>
                  <a
                    href={`/notes/${noteId}`}
                    onClick={evt => {
                      evt.preventDefault();
                      history.push(`/notes/${noteId}`);
                    }}
                  >
                    <SettingsIcon style={{ fill: grey600 }} />
                  </a>
                </ButtonRow>
              </Footer>
            </ExerciseWrapper>
          </PageItem>
        );
      })}
    </>
  );
};
