import styled from "@emotion/styled";
import TextareaAutosize from "react-autosize-textarea";
import { grey500, grey900, white } from "../colors";

export const MarkdownTextField = styled(TextareaAutosize)`
  font-family: "Source Sans Pro", sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  width: 100%;
  line-height: 1.8rem;
  text-align: left;
  color: ${grey900};
  background-color: ${white};
  border: none;
  resize: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding: 0.8rem 1rem 0.8rem;
  align-items: center;
  font-size: 1rem;
  outline: none;
  ::placeholder {
    color: ${grey500};
  }
  em {
    font-weight: 600;
    font-style: normal;
  }

  img {
    max-width: 100%;
    max-height: 24em;
  }
`;
