import * as t from "io-ts";
import { DateCodec } from "../IOTypes";

export const tagColours = ["RED", "BLUE", "GREEN", "YELLOW", "TEAL", "PURPLE"];
export const TagColour = t.keyof({
  RED: null,
  BLUE: null,
  GREEN: null,
  YELLOW: null,
  TEAL: null,
  PURPLE: null
});
export type TagColour = t.TypeOf<typeof TagColour>;
// export type TagColour = "RED" | "BLUE" | "GREEN" | "YELLOW" | "TEAL" | "PURPLE";

// ---------------------------
// Tag Actions
// ---------------------------
// This represents all the actions that can be applied to tags,
// including creating the tag itself.
//

export const SaveTag = t.type({
  type: t.literal("SAVE_TAG"),
  actionId: t.string,
  tagId: t.string,
  createdAt: DateCodec,
  tagName: t.string,
  tagColor: TagColour
});
export type SaveTag = t.TypeOf<typeof SaveTag>;

export const DeleteTag = t.type({
  type: t.literal("DELETE_TAG"),
  actionId: t.string,
  tagId: t.string,
  createdAt: DateCodec
});
export type DeleteTag = t.TypeOf<typeof DeleteTag>;

export const TagAction = t.union([SaveTag, DeleteTag]);
export type TagAction = t.TypeOf<typeof TagAction>;

// ---------------------------
// Tag State
// ---------------------------
// This represents the current state of a given tag.
//

export const TagState = t.type({
  tagId: t.string,
  createdAt: DateCodec,
  lastUpdatedAt: DateCodec,
  tagColor: TagColour,
  tagName: t.string,
  isDeleted: t.boolean
});
export type TagState = t.TypeOf<typeof TagState>;
