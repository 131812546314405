import { TagAction, TagState } from "./types";
import { AppDatabase } from "../Database";
import { initialTagState, updateTagState } from "./reducer";
import { useCallback, useMemo } from "react";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

export type TagStore = {
  addTagAction: (tagAction: TagAction) => Promise<TagState | null>;
  fetchTagById: (tagId: string) => Promise<TagState | null>;
  fetchTagByName: (tagName: string) => Promise<TagState | null>;
  fetchAllTags: () => Promise<TagState[]>;
  observeTags: (filter?: any) => Observable<TagState[]>;
};

const updateTagFromAction = (
  db: AppDatabase,
  tagAction: TagAction
): Promise<TagState | null> => {
  return db.tag_actions
    .atomicUpsert(db.tag_actions.fromTagAction(tagAction))
    .then(() => {
      return db.tag_states
        .findOne()
        .where("TagId")
        .eq(tagAction.tagId)
        .exec()
        .then(doc => doc && doc.toTag())
        .then(tagState =>
          tagState
            ? updateTagState(tagState, tagAction)
            : tagAction.type === "SAVE_TAG"
            ? initialTagState(tagAction)
            : null
        )
        .then(tagState => {
          if (tagState && tagState.isDeleted) {
            return db.tag_states
              .find()
              .where("tagId")
              .eq(tagState.tagId)
              .exec()
              .then(docs => docs.forEach(d => d.remove()))
              .then(() => tagState);
          } else if (tagState) {
            return db.tag_states
              .atomicUpsert(db.tag_states.fromTag(tagState))
              .then(() => tagState);
          } else {
            return tagState;
          }
        });
    });
};

export const useTagStore = (db: AppDatabase): TagStore => {
  const fetchTagById = useCallback(
    async (tagId: string): Promise<TagState | null> => {
      return db.tag_states
        .findOne()
        .where("tagId")
        .eq(tagId)
        .exec()
        .then(doc => doc && doc.toTag());
    },
    [db]
  );
  const fetchTagByName = useCallback(
    async (tagName: string): Promise<TagState | null> => {
      return db.tag_states
        .findOne()
        .where("tagName")
        .eq(tagName)
        .exec()
        .then(doc => doc && doc.toTag());
    },
    [db]
  );
  const fetchAllTags = useCallback((): Promise<TagState[]> => {
    return db.tag_states
      .find()
      .exec()
      .then(tagDocs => tagDocs.map(d => d.toTag()));
  }, [db]);

  const addTagAction = useCallback(
    async (tagAction: TagAction): Promise<TagState | null> => {
      return updateTagFromAction(db, tagAction);
    },
    [db]
  );

  const observeTags = useCallback(
    (filter?: any) => {
      return db.tag_states
        .find(filter)
        .$.pipe(map(docs => docs.map(doc => doc.toTag())));
    },
    [db]
  );
  return useMemo(
    () => ({
      fetchTagById,
      addTagAction,
      fetchAllTags,
      fetchTagByName,
      observeTags
    }),
    [addTagAction, fetchTagById, fetchAllTags, fetchTagByName, observeTags]
  );
};
