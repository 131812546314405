import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  animation-name: appear;
  animation-duration: 225ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  @keyframes appear {
    from {
      opacity: 0;
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  max-height: calc(100% - 96px);
  flex-direction: column;
  min-width: 120px;
  max-width: 600px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  margin: 12px;
  // Elevation-24
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
`;

type ModalProps = {
  onDismiss: () => void;
};
export const Modal: FunctionComponent<ModalProps> = ({
  onDismiss,
  children
}) => {
  return (
    <ModalOverlay onClick={onDismiss}>
      <ModalContainer>{children}</ModalContainer>
    </ModalOverlay>
  );
};
