import { blue600, grey400 } from "../colors";
import styled from "@emotion/styled";
import React from "react";

export type OptionKey = any;
export type Option = {
  key: OptionKey;
  title: string;
  description: string;
};

export type OptionGroup = {
  options: Option[];
  selected: OptionKey | null;
};

const Circle = ({ isFilled }: { isFilled: boolean }) => (
  <svg viewBox={"0 0 24 24"} xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={12}
      cy={12}
      r={10}
      fill={"none"}
      stroke={isFilled ? blue600 : grey400}
      strokeWidth={2}
    />
    {isFilled && <circle cx={12} cy={12} r={8} fill={blue600} />}
  </svg>
);

const OptionGroupDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`;

const OptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
   border: 1px solid ${grey400};
  border-radius: 2px;
  margin-bottom: 8px;
  padding: 16px;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
  }
  flex: 1;
  flex-basis: 240px;
  :hover {
    border: 1px solid ${blue600};
  }
`;

const OptionTextDiv = styled.div`
  padding: 2px 16px;
`;
const OptionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
`;

const OptionDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Option = ({
  optionTitle,
  optionDescription,
  selected,
  onSelect
}: {
  optionTitle: string;
  optionDescription: string;
  selected: boolean;
  onSelect: () => void;
}) => {
  return (
    <OptionDiv onClick={onSelect}>
      <Circle isFilled={selected} />
      <OptionTextDiv>
        <OptionTitle>{optionTitle}</OptionTitle>
        <OptionDescription>{optionDescription}</OptionDescription>
      </OptionTextDiv>
    </OptionDiv>
  );
};

export const OptionGroup = ({
  optionGroup,
  onSelect
}: {
  optionGroup: OptionGroup;
  onSelect: (selectedKey: OptionKey) => void;
}) => {
  return (
    <OptionGroupDiv>
      {optionGroup.options.map(option => (
        <Option
          selected={optionGroup.selected === option.key}
          optionDescription={option.description}
          optionTitle={option.title}
          onSelect={() => onSelect(option.key)}
        />
      ))}
    </OptionGroupDiv>
  );
};
