/*

A function to represent a queue

Created by Kate Morley - http://code.iamkate.com/ - and released under the terms
of the CC0 1.0 Universal legal code:

http://creativecommons.org/publicdomain/zero/1.0/legalcode

*/

/* Creates a new queue. A queue is a first-in-first-out (FIFO) data structure -
 * items are added to the end of the queue and removed from the front.
 */

export class Queue<T> {
  // initialise the queue and offset
  queue: T[] = [];
  offset = 0;

  constructor() {
    this.queue = [];
    this.offset = 0;
  }

  // Returns the length of the queue.
  getLength() {
    return this.queue.length - this.offset;
  }

  // Returns true if the queue is empty, and false otherwise.
  isEmpty() {
    return this.queue.length === 0;
  }

  /* Enqueues the specified item. The parameter is:
   *
   * item - the item to enqueue
   */
  enqueue(item: T) {
    this.queue.push(item);
  }

  /* Dequeues an item and returns it. If the queue is empty, the value
   * 'undefined' is returned.
   */
  dequeue() {
    // if the queue is empty, return immediately
    if (this.queue.length === 0) return undefined;

    // store the item at the front of the queue
    let item = this.queue[this.offset];

    // increment the offset and remove the free space if necessary
    if (++this.offset * 2 >= this.queue.length) {
      this.queue = this.queue.slice(this.offset);
      this.offset = 0;
    }

    // return the dequeued item
    return item;
  }

  /*
    Dequeues elements in a queue, up to a predefined limit
   */
  drain(limit?: number): T[] {
    const result: T[] = [];
    while (true) {
      const nextItem = this.dequeue();
      if (nextItem === undefined) {
        break;
      } else if (limit !== undefined && result.length >= limit) {
        break;
      } else {
        result.push(nextItem);
      }
    }
    return result;
  }
  /* Returns the item at the front of the queue (without dequeuing it). If the
   * queue is empty then undefined is returned.
   */
  peek() {
    return this.queue.length > 0 ? this.queue[this.offset] : undefined;
  }
}
