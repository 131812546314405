import { RxCollection, RxDocument, RxJsonSchema } from "rxdb";
import * as t from "io-ts";
import { DateCodec, tryDecode } from "../IOTypes";
import {TaggedNote} from "./types";
import {getSearchIndexText} from "./search";

// ------------------------
// Note State
// ------------------------

export const TaggedNoteDocType = t.type({
  noteId: t.string,
  createdAt: DateCodec,
  lastUpdatedAt: DateCodec,
  upcomingTimestamp: DateCodec,
  // The searchIndexText is a database-only field, representing
  // the text that can be regex'ed against to locate a note
  searchIndexText: t.string,
  // TagIds is a database-only field, indexing the IDs of the tags
  // associated with the tagged note
  tagIds: t.array(t.string),
  // TagNames is a database-only field, indexing the names of the tags
  // associated with the tagged note
  tagNames: t.array(t.string),

  payload: TaggedNote
});
export type TaggedNoteDocType = t.OutputOf<typeof TaggedNoteDocType>;
type TaggedNoteDocMethods = {
  toTaggedNote: () => TaggedNote;
};
export type TaggedNoteDocument = RxDocument<
  TaggedNoteDocType,
  TaggedNoteDocMethods
  >;
type TaggedNoteCollectionMethods = {
  fromTaggedNote: (taggedNote: TaggedNote) => TaggedNoteDocType;
};

export type TaggedNoteCollection = RxCollection<
  TaggedNoteDocument,
  TaggedNoteDocMethods,
  TaggedNoteCollectionMethods
  >;

export const taggedNoteSchema: RxJsonSchema<TaggedNoteDocType> = {
  title: "Represents the current state of a note",
  version: 0,
  type: "object",
  properties: {
    noteId: { type: "string", primary: true, final: false },
    createdAt: { type: "number", index: true },
    lastUpdatedAt: { type: "number", index: true },
    upcomingTimestamp: { type: "number", index: true },
    tagIds: { type: "array", items: { type: "string" } },
    tagNames: { type: "array", items: { type: "string" } },
    searchIndexText: { type: "string" },
    payload: { type: "object" }
  }
};

const taggedNoteDocMethods: TaggedNoteDocMethods = {
  toTaggedNote: function(this: TaggedNoteDocument) {
    return tryDecode(TaggedNote, this.toJSON().payload);
  }
};

const taggedNoteCollectionMethods: TaggedNoteCollectionMethods = {
  fromTaggedNote: function(this, taggedNote) {
    return TaggedNoteDocType.encode({
      payload: taggedNote,
      createdAt: taggedNote.createdAt,
      noteId: taggedNote.noteId,
      tagNames: taggedNote.tags.map(t => t.tagName),
      tagIds: taggedNote.tags.map(t => t.tagId),
      upcomingTimestamp: taggedNote.upcomingTimestamp,
      searchIndexText: getSearchIndexText(taggedNote.content),
      lastUpdatedAt: taggedNote.lastUpdatedAt
    });
  }
};

export const taggedNoteCollectionOpts = {
  name: "tagged_notes",
  schema: taggedNoteSchema,
  statics: taggedNoteCollectionMethods,
  methods: taggedNoteDocMethods,
  autoMigrate: false
};
