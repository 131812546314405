/*
LCE stands for Loading - Content - Error

It's a general way to represent data that may or may not have been fetched
from the server.
* */

export type LCE<C, E = string> = Loading | Content<C> | Error<E>;

export type Loading = {
  type: "Loading";
};

type Content<C> = {
  type: "Content";
  content: C;
};

type Error<E> = {
  type: "Error";
  error: E;
};

export const mapContent = <C, E, T>(
  lce: LCE<C, E>,
  f: (content: C) => T
): LCE<T, E> => {
  return lce.type === "Content"
    ? { type: "Content", content: f(lce.content) }
    : lce;
};

export const asContent = <C>(content: C): Content<C> => ({
  type: "Content",
  content
});
