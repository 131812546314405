import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  green700,
  grey600,
  grey800,
  red700,
  white
} from "../colors";
import { LeftRightLayout } from "../layout";
import cross from "../../assets/cross.svg";
import check from "../../assets/check-mark.svg";
import { ExerciseAction, ExerciseState, SubmitAnswer } from "../../Exercise/types";
import { exerciseStateIsAnswerable } from "../../Exercise/reducer";
import { v4 as uuid } from "uuid";
import { NoteContentRenderer } from "./noteContentRenderer";

const AnswerInstruction = styled.div`
  margin-top: 4px;
  color: ${grey600};
  font-size: 16px;
  a {
    text-decoration: none;
    color: ${red700};
  }
`;

const CorrectAnswer = styled.span`
  margin-top: 4px;
  font-size: 16px;
  font-weight: 600;
  color: ${green700};
`;

const WrongAnswer = styled.span`
  margin-top: 4px;
  font-size: 16px;
  font-weight: 600;
  color: ${red700};
`;

export const ExerciseWrapper = styled.div`
  background: ${white};
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  margin-top: 0.5rem;
  width: 100%;
`;

const AnswerInput = styled.input`
  color: ${grey800};
  opacity: 1;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 0.5rem 0 0.5rem;
  background: transparent;
  border: none;
  outline: none;
  width: 95%;
  box-sizing: border-box;

  :disabled {
    cursor: text;
  }
`;

const AnswerBox = styled.div`
  border-bottom: 2px solid ${grey600};
  margin-top: 4rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ExerciseComponent = ({
  exerciseState,
  setExerciseAction
}: {
  exerciseState: ExerciseState;
  setExerciseAction: (action: ExerciseAction) => void;
}) => {
  const submitAnswer = useCallback(
    (answer: string) => {
      const answerAction: SubmitAnswer = {
        actionId: uuid(),
        type: "SUBMIT_ANSWER",
        exerciseId: exerciseState.exerciseId,
        answer,
        createdAt: new Date()
      };
      setExerciseAction(answerAction);
    },
    [exerciseState.exerciseId, setExerciseAction]
  );
  const skip = useCallback(
    evt => {
      evt.preventDefault();
      setExerciseAction({
        actionId: uuid(),
        type: "SKIP",
        familiar: false,
        createdAt: new Date(),
        exerciseId: exerciseState.exerciseId
      });
    },
    [exerciseState.exerciseId, setExerciseAction]
  );
  const isActive = exerciseStateIsAnswerable(exerciseState);
  const lastAction = exerciseState.markedAnswers.slice(-1)[0];
  const lastActionId = lastAction && lastAction.actionId;
  const [showLastAnswer, setShowLastAnswer] = useState(true);
  const answerField = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (answerField && answerField.current) {
      answerField.current.focus();
    }
  }, [answerField]);
  useEffect(() => {
    if (answerField && answerField.current && isActive) {
      answerField.current.select();
    }
  }, [lastAction, isActive]);
  useEffect(() => setShowLastAnswer(true), [lastActionId]);
  useEffect(() => {
    if (showLastAnswer && isActive) {
      const timeoutId = setTimeout(() => setShowLastAnswer(false), 1500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [lastAction, showLastAnswer, isActive]);
  return (
    <ExerciseWrapper>
      <div>
        <NoteContentRenderer
          noteContents={exerciseState.note.content}
          clozeDisplayStatusMap={exerciseState.clozeStatuses}
        />
      </div>
      <div style={{ width: "100%" }}>
        <form
          onSubmit={evt => {
            evt.preventDefault();
            if (answerField.current) {
              submitAnswer(answerField.current.value);
            }
          }}
          style={{ width: "100%" }}
        >
          <AnswerBox>
            <AnswerInput
              type={"text"}
              ref={answerField}
              autoCapitalize="off"
              key={exerciseState.exerciseId}
              autoFocus={true}
              onChange={() => setShowLastAnswer(false)}
              disabled={!isActive}
            />
            {showLastAnswer && lastAction && lastAction.isCorrect && (
              <img src={check} alt={""} width={16} />
            )}
            {showLastAnswer && lastAction && !lastAction.isCorrect && (
              <img src={cross} alt={""} width={16} />
            )}
          </AnswerBox>
          <LeftRightLayout>
            {showLastAnswer &&
            lastAction &&
            lastAction.type === "SUBMIT_ANSWER" ? (
              lastAction.isCorrect ? (
                <AnswerInstruction>
                  <CorrectAnswer>{lastAction.answer}</CorrectAnswer> is correct.
                </AnswerInstruction>
              ) : (
                <AnswerInstruction>
                  <WrongAnswer>{lastAction.answer}</WrongAnswer> is wrong.
                </AnswerInstruction>
              )
            ) : (
              <AnswerInstruction>
                Type the answer, or {/*TODO: Replace <a> tag with button*/}
                <a href="/#" onClick={skip} style={{ cursor: "pointer" }}>
                  skip
                </a>
              </AnswerInstruction>
            )}
            {/*<Button onClick={flagQuestion}>*/}
            {/*  <FlagIcon />*/}
            {/*</Button>*/}
          </LeftRightLayout>
        </form>
      </div>
    </ExerciseWrapper>
  );
};
