import { useEffect, useState } from "react";
import { TagState } from "../../Tag/types";
import { Store } from "../../Store";
import { sortByName } from "../../Tag/sort";

export const useAllTagsSubscription = (store: Store) => {
  const [autocompleteTags, setAutocompleteTags] = useState<TagState[] | null>(
    null
  );
  useEffect(() => {
    const sub = store.tags
      .observeTags()
      .subscribe(tags => setAutocompleteTags(sortByName(tags)));
    return () => sub.unsubscribe();
  }, [store.tags]);
  return autocompleteTags;
};
