import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { v4 as uuid } from "uuid";
import produce from "immer";
import styled from "@emotion/styled";
import { grey200, white } from "../colors";
import { PrimaryButton, SecondaryButton } from "../button";
import Mousetrap from "mousetrap";
import { QuestionCard } from "../pages/notes";
import { TagEditor } from "./tagEditor";
import { MarkdownTextField } from "./markdownTextField";
import { Store } from "../../Store";
import { TagState } from "../../Tag/types";
import { getAvailableClozes } from "../../Cloze/cloze";
import { escapeCloze } from "../../Cloze/parser";
import { sortByName } from "../../Tag/sort";
import { TaggedNote } from "../../TaggedNote/types";

type Selection = {
  start: number;
  end: number;
};

const ButtonRow = styled.div`
  position: relative;
  top: -7px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem;
  background-color: ${white};
  margin: 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const SaveButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.4rem 1rem;
  width: 100%;
  border-top: 1px solid ${grey200};
  .saveButton {
    margin-left: 12px;
  }
  flex-wrap: wrap;
  .tagEditor {
    flex-grow: 1;
    min-width: 240px;
    div {
      border: none;
    }
  }
`;

const clozify = (
  question: string,
  { start, end }: Selection,
  nextNumber: number
) => {
  const beginning = question.slice(0, start);
  const answer = question.slice(start, end);
  const last = question.slice(end);
  const cloze = `{{A${nextNumber}::${escapeCloze(answer)}}}`;
  return `${beginning}${cloze}${last}`;
};

const useShortcut = (
  keys: string,
  callback: (e: ExtendedKeyboardEvent, combo: string) => any
) => {
  useEffect(() => {
    Mousetrap.bind(keys, (e, combo) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      callback(e, combo);
    });
    return () => {
      Mousetrap.unbind(keys);
    };
  }, [keys, callback]);
};

type NoteEditorProps = {
  initialNote: TaggedNote;
  initialTags: TagState[];
  saveHook?: (note: TaggedNote) => void;
  saveLabel?: string;
  cancelCallback?: () => void;
  store: Store;
};
export const NoteEditor = ({
  initialNote,
  initialTags,
  saveHook,
  saveLabel = "Save",
  cancelCallback,
  store
}: NoteEditorProps) => {
  const [note, setNote] = useState(initialNote);
  const [isSaving, setIsSaving] = useState(false);
  const autocompleteTags = initialTags;
  const allTags = useMemo(() => {
    return autocompleteTags.reduce((accum, tag) => {
      accum[tag.tagId] = tag;
      return accum;
    }, {} as { [key: string]: TagState });
  }, [autocompleteTags]);
  const textField = useRef<HTMLTextAreaElement>(null);
  const saveNote = useCallback(
    (newNote: TaggedNote) => {
      store
        .enqueueAction(
          {
            actionId: uuid(),
            type: "SAVE_TAGGED_NOTE",
            noteId: newNote.noteId,
            createdAt: new Date(),
            content: newNote.content,
            tags: newNote.tags
          }
        )
        .then(() => {
          setNote(
            produce(newNote, d => {
              d.noteId = uuid();
              d.createdAt = new Date();
            })
          );
          setIsSaving(false);
          saveHook && saveHook(newNote);
        });
    },
    [store, saveHook]
  );
  const [selection, setSelection] = useState<Selection | null>(null);
  const clozes = useMemo(() => getAvailableClozes(note.content), [
    note.content
  ]);
  const setCloze = useCallback(() => {
    if (selection) {
      const firstLength = note.content[0].content.length;
      const newQuestion = clozify(
        note.content[0].content,
        selection,
        clozes.length
      );
      const secondLength = newQuestion.length;
      setNote(clz =>
        produce(clz, d => {
          d.content[0].content = newQuestion;
        })
      );
      if (textField && textField.current) {
        textField.current.selectionStart =
          secondLength - firstLength + selection.end;
        textField.current.selectionEnd =
          secondLength - firstLength + selection.end;
      }
    }
    return false;
  }, [selection, setNote, clozes.length, note.content]);

  const hasCloze = clozes.length >= 1;
  const submit = useCallback(() => {
    if (!isSaving && hasCloze) {
      saveNote(note);
    }
  }, [saveNote, note, isSaving, hasCloze]);

  // Keyboard shortcuts
  useShortcut("mod+shift+a", setCloze);
  useShortcut("shift+enter", submit);

  return (
    <QuestionCard style={{ padding: 0 }}>
      <MarkdownTextField
        ref={textField}
        autoFocus={true}
        value={note.content[0].content}
        className={"mousetrap"}
        placeholder={"Add a new note"}
        rows={3}
        onSelect={(evt: any) => {
          const start = evt.target.selectionStart;
          const end = evt.target.selectionEnd;
          setSelection(end > start ? { start, end } : null);
        }}
        onChange={(evt: any) => {
          evt.persist();
          setNote(clz =>
            produce(clz, d => {
              d.content[0].content = evt.target.value;
            })
          );
        }}
        onBlur={() => setSelection(null)}
      />
      <ButtonRow>
        <SecondaryButton disabled={!selection} onMouseDown={setCloze}>
          Set as answer
        </SecondaryButton>
      </ButtonRow>

      <SaveButtonRow>
        <TagEditor
          selectedTags={sortByName(
            note.tags
          )}
          availableTags={Object.values(allTags)}
          selectTag={tags => {
            setNote(n =>
              produce(n, d => {
                d.tags = tags;
              })
            );
          }}
          saveTag={saveTag => {
            store
              .enqueueAction(saveTag)
              .then(
                tag =>
                  tag &&
                  setNote(n =>
                    produce(n, d => {
                      d.tags.push(tag);
                    })
                  )
              );
          }}
        />
        <Row>
          {cancelCallback && (
            <SecondaryButton onClick={cancelCallback}>Cancel</SecondaryButton>
          )}
          <PrimaryButton
            className={"saveButton"}
            disabled={clozes.length < 1 || isSaving}
            onClick={() => saveNote(note)}
          >
            {isSaving ? "Saving..." : saveLabel}
          </PrimaryButton>
        </Row>
      </SaveButtonRow>
    </QuestionCard>
  );
};
