import produce from "immer";
import { myIntervalDurations, nextInterval } from "../interval";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import { SaveTaggedNote, TaggedNote, TaggedNoteAction } from "./types";
import { NoteState } from "../Note/types";

export const initialTaggedNote = (action: SaveTaggedNote): TaggedNote => {
  return {
    noteId: action.noteId,
    createdAt: action.createdAt,
    lastUpdatedAt: action.createdAt,
    content: action.content,
    currentDuration: myIntervalDurations[0],
    upcomingTimestamp: DateTime.fromJSDate(action.createdAt)
      .plus(myIntervalDurations[0])
      .toJSDate(),
    onlyFirstBlankAnswerable: true,
    hideOne: true,
    answerInOrder: true,
    isDeleted: false,
    tags: action.tags,
    exerciseStatuses: []
  };
};

export const updateTaggedNote = (
  taggedNote: TaggedNote,
  action: TaggedNoteAction
): TaggedNote => {
  switch (action.type) {
    case "SAVE_TAGGED_NOTE":
      return produce(taggedNote, note => {
        note.lastUpdatedAt = action.createdAt;
        note.content = action.content;
        note.tags = action.tags;
      });
    case "CONFIGURE_NOTE":
      return produce(taggedNote, note => {
        note.lastUpdatedAt = action.createdAt;
        note.answerInOrder = action.answerInOrder;
        note.hideOne = action.hideOne;
        note.onlyFirstBlankAnswerable = action.onlyFirstBlankAnswerable;
      });
    case "DELETE_NOTE":
      return produce(taggedNote, note => {
        note.lastUpdatedAt = action.createdAt;
        note.isDeleted = true;
      });
    case "COMPLETE_EXERCISE":
      const exerciseStatuses = [
        ...taggedNote.exerciseStatuses,
        {
          correctness: action.correctness,
          timestamp: action.timestamp
        }
      ];
      const interval = nextInterval(
        taggedNote.createdAt,
        exerciseStatuses,
        myIntervalDurations
      );
      return produce(taggedNote, note => {
        note.lastUpdatedAt = action.createdAt;
        note.exerciseStatuses = exerciseStatuses;
        note.upcomingTimestamp = interval.upcomingTimestamp;
        note.currentDuration = interval.currentDuration;
      });
    case "SAVE_TAG":
      return produce(taggedNote, note => {
        note.tags.forEach(tag => {
          if (tag.tagId === action.tagId) {
            tag.tagName = action.tagName;
            tag.tagColor = action.tagColor;
            taggedNote.lastUpdatedAt = action.createdAt;
          }
        });
      });
  }
};

export const emptyTaggedNote = (): TaggedNote => {
  const now = new Date();
  return {
    noteId: uuid(),
    createdAt: now,
    lastUpdatedAt: now,
    content: [{ type: "MARKDOWN_BLOCK", content: "" }],
    tags: [],
    currentDuration: myIntervalDurations[0],
    upcomingTimestamp: now,
    isDeleted: false,
    onlyFirstBlankAnswerable: false,
    hideOne: false,
    answerInOrder: false,
    exerciseStatuses: []
  };
};

export const taggedNoteToNoteState = (taggedNote: TaggedNote): NoteState => ({
  noteId: taggedNote.noteId,
  createdAt: taggedNote.createdAt,
  lastUpdatedAt: taggedNote.lastUpdatedAt,
  content: taggedNote.content,
  tagIds: taggedNote.tags.map(t => t.tagId),
  currentDuration: taggedNote.currentDuration,
  upcomingTimestamp: taggedNote.upcomingTimestamp,
  isDeleted: taggedNote.isDeleted,
  answerInOrder: taggedNote.answerInOrder,
  hideOne: taggedNote.hideOne,
  onlyFirstBlankAnswerable: taggedNote.onlyFirstBlankAnswerable
});
