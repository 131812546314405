// The (?<!\\) is to avoid matching against escaped braces `\}`
export const clozeRegex = /{{(.*?::.*?(?:::.*?)?)}}/;

export const escapeCloze = (s: string) => {
  const spaced = ` ${s} `;
  return spaced.replace(/{/g, "\\{").replace(/}/g, "\\}");
};

export const unescapeCloze = (s: string) => {
  return s
    .trim()
    .replace(/\\{/g, "{")
    .replace(/\\}/g, "}")
    .replace(/\\_/g, "_");
};

const stringToCloze = (str: string) => {
  const [name, answer, hint] = str.split("::");
  return {
    name,
    answer: unescapeCloze(answer),
    hint: hint || null
  };
};

export const tokenizeCloze = (
  eat: (subvalue: string) => any,
  value: string,
  silent: boolean = false
) => {
  const match = /^{{(.*?::.*?(?:::.*?)?)}}/.exec(value);
  if (match) {
    if (silent) {
      return true;
    } else {
      const add = eat(match[0]);
      return add({
        type: "cloze",
        data: stringToCloze(match[1])
      });
    }
  }
};

tokenizeCloze.locator = (value: string, fromIndex: number) => {
  return value.indexOf("{{", fromIndex);
};
