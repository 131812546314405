import { DateTime } from "luxon";

const dayInMilliseconds = 86400 * 1000;

export const formatDatetimeDuration = (
  fromDate: DateTime,
  toDate: DateTime
): string => {
  // @ts-ignore
  const diffMilliseconds: number = toDate - fromDate;
  if (Math.abs(diffMilliseconds) > dayInMilliseconds) {
    return fromDate.toFormat("d MMM y");
  } else if (0 <= diffMilliseconds && diffMilliseconds <= 60 * 1000) {
    return `less than a minute ago`;
  } else {
    return fromDate.toRelative({ base: toDate }) || "null";
  }
};
