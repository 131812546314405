import { TagAction, TagState } from "./types";
import { RxCollection, RxDocument, RxJsonSchema } from "rxdb";
import * as t from "io-ts";
import { DateCodec, tryDecode } from "../IOTypes";
///
/// TagAction
///

export const TagActionDocType = t.type({
  actionId: t.string,
  createdAt: DateCodec,
  payload: TagAction
});
export type TagActionDocType = t.OutputOf<typeof TagActionDocType>;
type TagActionDocMethods = {
  toTagAction: () => TagAction;
};
export type TagActionDocument = RxDocument<
  TagActionDocType,
  TagActionDocMethods
>;
type TagActionCollectionMethods = {
  fromTagAction: (action: TagAction) => TagActionDocType;
};
export type TagActionCollection = RxCollection<
  TagActionDocType,
  TagActionDocMethods,
  TagActionCollectionMethods
>;

export const TagActionSchema: RxJsonSchema<TagActionDocType> = {
  title: "An action against an Tag",
  version: 0,
  type: "object",
  properties: {
    actionId: { type: "string", primary: true },
    createdAt: { type: "number", index: true },
    payload: { type: "object" }
  },
  required: ["actionId", "createdAt", "payload"]
};

const TagActionDocMethods: TagActionDocMethods = {
  toTagAction: function(this: TagActionDocument): TagAction {
    return tryDecode(TagAction, this.toJSON().payload);
  }
};

const TagActionCollectionMethods: TagActionCollectionMethods = {
  fromTagAction: function(this: TagActionCollection, action: TagAction) {
    return TagActionDocType.encode({
      createdAt: action.createdAt,
      payload: action,
      actionId: action.actionId
    });
  }
};

export const tagActionCollectionOpts = {
  name: "tag_actions",
  schema: TagActionSchema,
  methods: TagActionDocMethods,
  statics: TagActionCollectionMethods
};

// ---------------------------
// Tag State
// ---------------------------
// Remember, this is not meant to be synced to other sites!
// Instead, it should be updated on TagAction insert.

export const TagDocType = t.type({
  tagId: t.string,
  tagName: t.string,
  lastUpdatedAt: DateCodec,
  payload: TagState
});

export type TagDocType = t.OutputOf<typeof TagDocType>;
type TagDocMethods = {
  toTag: () => TagState;
};
export type TagDocument = RxDocument<TagDocType, TagDocMethods>;

type TagCollectionMethods = {
  fromTag: (Tag: TagState) => TagDocType;
};
export type TagStateCollection = RxCollection<
  TagDocType,
  TagDocMethods,
  TagCollectionMethods
>;

export const TagSchema: RxJsonSchema<TagDocType> = {
  title: "A workable Tag",
  version: 0,
  type: "object",
  properties: {
    tagId: { type: "string", primary: true },
    tagName: { type: "string", index: true },
    lastUpdatedAt: { type: "number", index: true },
    payload: { type: "object" }
  },
  required: ["tagId", "lastUpdatedAt", "payload"]
};

const TagDocMethods: TagDocMethods = {
  toTag: function(this: TagDocument) {
    return tryDecode(TagState, this.toJSON().payload);
  }
};

const TagCollectionMethods: TagCollectionMethods = {
  fromTag: function(this: TagStateCollection, tag: TagState) {
    return TagDocType.encode({
      payload: tag,
      lastUpdatedAt: tag.lastUpdatedAt,
      tagId: tag.tagId,
      tagName: tag.tagName
    });
  }
};

export const tagCollectionOpts = {
  name: "tag_states",
  schema: TagSchema,
  methods: TagDocMethods,
  statics: TagCollectionMethods,
  autoMigrate: false
};
