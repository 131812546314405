import { AppDatabase } from "../Database";
import { ExerciseStore, useExerciseStore } from "../Exercise/store";
import { ExerciseAction, ExerciseState } from "../Exercise/types";
import { v4 as uuid } from "uuid";
import { NoteState } from "../Note/types";
import {
  createExerciseFromNote,
  exerciseStateIsAnswerable,
  exerciseStateIsCorrect
} from "../Exercise/reducer";
import { TagStore, useTagStore } from "../Tag/store";
import { TagState } from "../Tag/types";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { pushActionBuilder, SyncStatus, useFetcher } from "./sync";
import { Queue } from "./queue";
import { Action } from "./types";
import { TaggedNoteStore, useTaggedNoteStore } from "../TaggedNote/store";
import { TaggedNote } from "../TaggedNote/types";
import { taggedNoteToNoteState } from "../TaggedNote/reducer";

/**
 * The store manages everything related to data.
 *
 * This handles data access, reads, and writes to both the IndexedDB store,
 * and to the remote server.
 */

export type ExerciseNote = {
  exerciseState: ExerciseState;
  noteState: NoteState;
};

export type Store = {
  // enqueueAction is the most important method in the store
  // Any important action in the app has to go through this method
  enqueueAction: (action: Action) => Promise<any>;

  // Sub-stores
  exercises: ExerciseStore;
  tags: TagStore;
  taggedNotes: TaggedNoteStore;
  // Sync statuses
  syncStatus: SyncStatus | null;

  // Functions that span multiple stores
  newExerciseGroup: (
    exerciseGroupId: string,
    notes: TaggedNote[]
  ) => Promise<ExerciseState[]>;
  fetchExerciseNotesByGroupId: (
    exerciseGroupId: string
  ) => Promise<ExerciseNote[]>;
  fetchExerciseNotesByExercise: (
    exercise: ExerciseState
  ) => Promise<ExerciseNote | null>;
  launchNextDueExercise: (now: Date) => Promise<ExerciseNote | null>;

  observeTaggedNotes: (
    filter?: any,
    limit?: number
  ) => Observable<TaggedNote[]>;
};

export const useStore = (
  db: AppDatabase,
  sessionToken: string | null
): Store => {
  const graphQLClient = useMemo(
    () =>
      sessionToken
        ? new GraphQLClient(
            "https://retent-api.autonomous-agent.com/v1/graphql",
            {
              headers: {
                Authorization: `Bearer ${sessionToken}`
              }
            }
          )
        : null,
    [sessionToken]
  );

  const exercises = useExerciseStore(db);
  const tags = useTagStore(db);
  const taggedNotes = useTaggedNoteStore(db);

  const [currentAction, setCurrentAction] = useState<Action | null>(null);
  const queue = useRef(new Queue<[Action, (response: any) => unknown]>());
  const syncQueue = useRef(new Queue<Action>());

  const fetchExerciseNotesByGroupId = (
    exerciseGroupId: string
  ): Promise<ExerciseNote[]> => {
    return exercises
      .fetchExercisesByGroupId(exerciseGroupId)
      .then(exercises => {
        return Promise.all(
          exercises.map(exerciseState =>
            taggedNotes.fetchNoteById(exerciseState.note.noteId).then(
              taggedNote =>
                taggedNote && {
                  exerciseState,
                  noteState: taggedNoteToNoteState(taggedNote)
                }
            )
          )
        ).then(xs => xs.filter(x => !!x) as ExerciseNote[]);
      });
  };

  const updateExerciseNote = useCallback(
    (exerciseAction: ExerciseAction): Promise<ExerciseNote | null> => {
      return exercises
        .addExerciseAction(exerciseAction)
        .then(exerciseState => {
          return (
            exerciseState &&
            taggedNotes.fetchNoteById(exerciseState.note.noteId).then(
              taggedNote =>
                taggedNote && {
                  exerciseState,
                  noteState: taggedNoteToNoteState(taggedNote)
                }
            )
          );
        })
        .then(exerciseNote => {
          if (!exerciseNote) {
            return null;
          }
          const { exerciseState, noteState } = exerciseNote;
          if (!exerciseStateIsAnswerable(exerciseState)) {
            return taggedNotes
              .addTaggedNoteAction({
                type: "COMPLETE_EXERCISE",
                timestamp: exerciseAction.createdAt,
                correctness: exerciseStateIsCorrect(exerciseState),
                createdAt: exerciseAction.createdAt,
                actionId: uuid(),
                exerciseId: exerciseState.exerciseId,
                noteId: noteState.noteId
              })
              .then(
                taggedNote =>
                  taggedNote && {
                    exerciseState,
                    noteState: taggedNoteToNoteState(taggedNote)
                  }
              );
          } else {
            return exerciseNote;
          }
        });
    },
    [exercises, taggedNotes]
  );

  const fetchExerciseNotesByExercise = (exerciseState: ExerciseState) => {
    return taggedNotes.fetchNoteById(exerciseState.note.noteId).then(
      taggedNote =>
        taggedNote && {
          exerciseState,
          noteState: taggedNoteToNoteState(taggedNote)
        }
    );
  };

  const launchNextDueExercise = (now: Date): Promise<ExerciseNote | null> => {
    return taggedNotes
      .observeNextDueNotes(now)
      .pipe(first())
      .toPromise()
      .then(notes => (notes.length > 0 ? notes[0] : null))
      .then(note => note && createExerciseFromNote(note, uuid(), 0))
      .then(
        action => action && enqueueAction(action)
      ) as Promise<ExerciseNote | null>;
  };

  const observeTaggedNotes = (filter: any, limit?: number) => {
    return taggedNotes.observeNotes(filter, limit);
  };

  const newExerciseGroup = useCallback(
    async (exerciseGroupId: string, notes: TaggedNote[]) => {
      const actions = notes.map((s, idx) =>
        createExerciseFromNote(s, exerciseGroupId, idx)
      );
      return await Promise.all(actions.map(ex => enqueueAction(ex))).then(
        exs => exs.filter(x => !!x) as ExerciseState[]
      );
    },
    []
  );

  const processAction = useCallback(
    async (action: Action) => {
      switch (action.type) {
        case "CREATE_EXERCISE":
        case "SUBMIT_ANSWER":
        case "SKIP":
          return updateExerciseNote(action);
        case "SAVE_TAG":
          await taggedNotes.addTaggedNoteAction(action);
          return tags.addTagAction(action);
        case "DELETE_TAG":
          return tags.addTagAction(action);
        case "CONFIGURE_NOTE":
        case "DELETE_NOTE":
          return taggedNotes.addTaggedNoteAction(action);
        case "SAVE_NOTE":
          return Promise.all(
            action.tagIds.map(id => tags.fetchTagById(id))
          ).then(tags =>
            taggedNotes.addTaggedNoteAction({
              type: "SAVE_TAGGED_NOTE",
              noteId: action.noteId,
              actionId: uuid(),
              createdAt: action.createdAt,
              content: action.content,
              tags: tags.filter(x => !!x) as TagState[]
            })
          );
        case "COMPLETE_EXERCISE":
        case "SAVE_TAGGED_NOTE":
          return taggedNotes.addTaggedNoteAction(action);
      }
    },
    [taggedNotes, tags, updateExerciseNote]
  );

  const dequeueAndProcessAction = useCallback(() => {
    if (!currentAction) {
      const nextItem = queue.current.dequeue();
      if (nextItem) {
        const [nextAction, cb] = nextItem;
        setCurrentAction(nextAction);
        processAction(nextAction).then(resp => {
          syncQueue.current.enqueue(nextAction);
          cb(resp);
          setCurrentAction(null);
        });
      }
    }
  }, [currentAction, processAction]);
  useEffect(() => {
    const handle = setInterval(dequeueAndProcessAction, 10);
    return () => clearInterval(handle);
  }, [dequeueAndProcessAction]);

  // Push to server once every 5 seconds
  useEffect(() => {
    if (graphQLClient) {
      const pushCallback = pushActionBuilder(db, graphQLClient);
      const handle = setInterval(async () => {
        const toBeSynced = syncQueue.current.drain(1000);
        if (toBeSynced.length > 0) {
          await pushCallback(toBeSynced);
        }
      }, 1000 * 5);
      return () => clearInterval(handle);
    }
  }, [syncQueue, graphQLClient, db]);
  // usePusher(db, graphQLClient);
  const syncStatus = useFetcher(db, graphQLClient, processAction);

  const enqueueAction = (action: Action) =>
    new Promise((resolve: (resp: unknown) => void) =>
      queue.current.enqueue([action, resolve])
    );
  return {
    exercises,
    tags,
    taggedNotes,
    syncStatus,
    newExerciseGroup,
    fetchExerciseNotesByGroupId,
    fetchExerciseNotesByExercise,
    launchNextDueExercise,
    observeTaggedNotes,
    enqueueAction
  };
};
