import { ClozeDeletion, splitByClozeDeletion } from "./cloze";
import { ClozeDisplayStatus, ClozeDisplayStatusMap } from "../Exercise/types";

const renderCloze = (
  cloze: ClozeDeletion,
  clozeDisplayStatus: ClozeDisplayStatus
): string => {
  switch (clozeDisplayStatus) {
    case "answerable":
      const ans = Array(cloze.answer.length)
        .fill("\\_")
        .join("");
      return `{{${cloze.name}::${ans}::${cloze.hint}}}`;
    case "hidden":
      return `${Array(cloze.answer.length)
        .fill("\\_")
        .join("")}`;
    case "revealed":
      return cloze.answer;
    default:
      return `{{${cloze.name}:: ${cloze.answer} ::${cloze.hint}}}`;
  }
};

const renderQuestion = (
  template: (string | ClozeDeletion)[],
  clozeDisplayStatuses: ClozeDisplayStatusMap
) => {
  return template
    .map(elem =>
      typeof elem === "string"
        ? elem
        : renderCloze(elem, clozeDisplayStatuses[elem.name] || "revealed")
    )
    .join("");
};

export const substituteClozes = (
  content: string,
  clozeDisplayStatusMap: ClozeDisplayStatusMap
): string => {
  return renderQuestion(splitByClozeDeletion(content), clozeDisplayStatusMap);
};
