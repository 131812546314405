import { CenteredPage, PageWithNav } from "../page";
import React from "react";

export const Page404 = () => {
  return (
    <PageWithNav navLocation={null}>
      <CenteredPage style={{height: "70vh"}}>Error 404: Looks like the page is not found</CenteredPage>
    </PageWithNav>
  );
};
