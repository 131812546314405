import { useCallback, useMemo } from "react";
import { ExerciseAction, ExerciseState } from "./types";
import { initialExerciseState, updateExerciseState } from "./reducer";
import { AppDatabase } from "../Database";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export type ExerciseStore = {
  addExerciseAction: (
    exerciseAction: ExerciseAction
  ) => Promise<ExerciseState | null>;
  fetchExercisesByNoteId: (noteId: string) => Promise<ExerciseState[]>;
  fetchExercisesByGroupId: (groupId: string) => Promise<ExerciseState[]>;
  observeActiveExercises: (
    activeSince: Date,
    loadLimit: number
  ) => Observable<ExerciseState[]>;
};

const updateExerciseFromAction = (
  db: AppDatabase,
  exerciseAction: ExerciseAction
): Promise<ExerciseState | null> => {
  return db.exercise_actions
    .atomicUpsert(db.exercise_actions.fromExerciseAction(exerciseAction))
    .then(() => {
      switch (exerciseAction.type) {
        case "CREATE_EXERCISE":
          const exerciseState = initialExerciseState(exerciseAction);
          return db.exercises
            .atomicUpsert(db.exercises.fromExercise(exerciseState))
            .then(() => exerciseState);

        case "SKIP":
        case "SUBMIT_ANSWER":
          return db.exercises
            .findOne()
            .where("exerciseId")
            .eq(exerciseAction.exerciseId)
            .exec()
            .then(doc => doc && doc.toExercise())
            .then(ex => ex && updateExerciseState(ex, exerciseAction))
            .then(ex => {
              if (ex) {
                return db.exercises
                  .atomicUpsert(db.exercises.fromExercise(ex))
                  .then(() => ex);
              } else {
                return ex;
              }
            });
      }
    });
};

export const useExerciseStore = (db: AppDatabase): ExerciseStore => {
  // Add action to database / sync it with server
  const addExerciseAction = useCallback(
    async (exerciseAction: ExerciseAction): Promise<ExerciseState | null> => {
      return updateExerciseFromAction(db, exerciseAction);
    },
    [db]
  );

  // Helper functions

  const fetchExercisesByNoteId = useCallback(
    (noteId: string) => {
      return db.exercises
        .find()
        .where("noteId")
        .eq(noteId)
        .sort({ updatedAt: "asc" })
        .exec()
        .then(docs => Promise.all(docs.map(d => d.toExercise())));
    },
    [db]
  );

  const fetchExercisesByGroupId = useCallback(
    (exerciseGroupId: string): Promise<ExerciseState[]> => {
      return db.exercises
        .find()
        .where("exerciseGroupId")
        .eq(exerciseGroupId)
        .exec()
        .then(docs => {
          return Promise.all(docs.map(doc => doc.toExercise()));
        });
    },
    [db]
  );

  const observeActiveExercises = useCallback(
    (activeSince: Date, loadLimit: number) => {
      return db.exercises
        .find()
        .where("updatedAt")
        .gte(activeSince.getTime())
        .sort({ updatedAt: "desc" })
        .limit(loadLimit)
        .$.pipe(map(docs => docs.map(d => d.toExercise())));
    },
    [db]
  );
  return useMemo(
    () => ({
      addExerciseAction,
      fetchExercisesByNoteId,
      fetchExercisesByGroupId,
      observeActiveExercises
    }),
    [
      addExerciseAction,
      fetchExercisesByNoteId,
      fetchExercisesByGroupId,
      observeActiveExercises
    ]
  );
};
