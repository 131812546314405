import { NoteContentBlock } from "../../Note/types";
import { ClozeDisplayStatusMap } from "../../Exercise/types";
import { Markdown } from "../markdown";
import React from "react";
import { substituteClozes } from "../../Cloze/renderer";

export const NoteContentRenderer = ({
  noteContents,
  clozeDisplayStatusMap
}: {
  noteContents: NoteContentBlock[];
  clozeDisplayStatusMap: ClozeDisplayStatusMap;
}) => {
  return (
    <>
      {noteContents.map((n, idx) => (
        <Markdown
          key={idx}
          source={substituteClozes(n.content, clozeDisplayStatusMap)}
          clozeDisplayStatusMap={clozeDisplayStatusMap}
        />
      ))}
    </>
  );
};
