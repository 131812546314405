import styled from "@emotion/styled";
import * as React from "react";
import { blue600 } from "./colors";

const time = -0.2;
const LoadingWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${blue600};
    animation: lds-grid ${-time * 8}s linear infinite;
  }
  div:nth-of-type(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  div:nth-of-type(2) {
    top: 8px;
    left: 32px;
    animation-delay: ${time}s;
  }
  div:nth-of-type(3) {
    top: 8px;
    left: 56px;
    animation-delay: ${time * 2}s;
  }
  div:nth-of-type(4) {
    top: 32px;
    left: 8px;
    animation-delay: ${time * 7}s;
  }
  div:nth-of-type(5) {
    top: 32px;
    left: 32px;
    animation-delay: ${time * 4}s;
  }
  div:nth-of-type(6) {
    top: 32px;
    left: 56px;
    animation-delay: ${time * 3}s;
  }
  div:nth-of-type(7) {
    top: 56px;
    left: 8px;
    animation-delay: ${time * 6}s;
  }
  div:nth-of-type(8) {
    top: 56px;
    left: 32px;
    animation-delay: ${time * 5}s;
  }
  div:nth-of-type(9) {
    top: 56px;
    left: 56px;
    animation-delay: ${time * 4}s;
  }
  @keyframes lds-grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
  }
`;

export const Loader = () => (
  <LoadingWrapper>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </LoadingWrapper>
);
