import { SaveTag, TagAction, TagState } from "./types";
import produce from "immer";

export const initialTagState = (tagAction: SaveTag): TagState => {
  return {
    tagId: tagAction.tagId,
    createdAt: tagAction.createdAt,
    lastUpdatedAt: tagAction.createdAt,
    tagColor: tagAction.tagColor,
    tagName: tagAction.tagName,
    isDeleted: false
  };
};

export const updateTagState = (
  tagState: TagState,
  tagAction: TagAction
): TagState => {
  if (tagAction.tagId !== tagState.tagId) {
    console.warn(`Warning: Attempting to update a tag with an action with the wrong ID. 
    Action tagID: ${tagAction.tagId}, State tagID: ${tagState.tagId}`);
    return tagState;
  }
  if (tagState.isDeleted) {
    // Tag has been deleted, no need to do anything else
    return tagState;
  }
  switch (tagAction.type) {
    case "SAVE_TAG":
      return produce(tagState, tag => {
        tag.tagName = tagAction.tagName;
        tag.tagColor = tagAction.tagColor;
        tag.lastUpdatedAt = tagAction.createdAt;
      });
    case "DELETE_TAG":
      return produce(tagState, tag => {
        tag.isDeleted = true;
      });
  }
};
