/**
 * @since 0.5.0
 */
import * as t from "io-ts";
import { either, isRight } from "fp-ts/lib/Either";
import { Duration } from "luxon";
import { Decoder } from "io-ts";

/**
 * @since 0.5.0
 */
export interface DateFromNumberC extends t.Type<Date, number, unknown> {}

/**
 * @example
 * import { DateFromNumber } from 'io-ts-types/lib/DateFromNumber'
 * import { right } from 'fp-ts/lib/Either'
 *
 * const date = new Date(1973, 10, 30)
 * const input = date.getTime()
 * assert.deepStrictEqual(DateFromNumber.decode(input), right(date))
 *
 * @since 0.5.0
 */
export const DateCodec: DateFromNumberC = new t.Type<Date, number, unknown>(
  "DateCodec",
  (u): u is Date => u instanceof Date,
  (u, c) =>
    either.chain(t.number.validate(u, c), n => {
      const d = new Date(n);
      return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d);
    }),
  a => a.getTime()
);

export const DurationCodec = new t.Type<Duration, string, unknown>(
  "DurationCodec",
  (u): u is Duration => u instanceof Duration,
  (u, c) =>
    either.chain(t.string.validate(u, c), n => {
      const d = Duration.fromISO(n);
      return d.isValid ? t.success(d) : t.failure(u, c);
    }),
  d => d.toISO()
);

export const tryDecode = <T>(codec: Decoder<unknown, T>, input: unknown): T => {
  const decoded = codec.decode(input);
  if (isRight(decoded)) {
    return decoded.right;
  } else {
    console.error(input);
    decoded.left.forEach(err => console.error(err));
    throw new Error();
  }
};
