import React, { Fragment } from "react";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";
import {
  blue600,
  blue900,
  green800,
  grey100,
  grey700,
  grey800,
  red600,
  red700
} from "./colors";
import { tokenizeCloze } from "../Cloze/parser";
import { ClozeDeletion, splitByClozeDeletion } from "../Cloze/cloze";
import { ClozeDisplayStatusMap } from "../Exercise/types";

const MarkdownCodeBlock = (clozeDisplayStatusMap: ClozeDisplayStatusMap) => (
  props: any
) => {
  // This is a bit of a hack, but
  // props.value can contain clozes. We want to extract the clozes
  // and render them separately
  const InlineClozeComp = InlineCloze(clozeDisplayStatusMap);
  return (
    <pre>
      <code>
        {splitByClozeDeletion(props.value).map((x, idx) =>
          typeof x === "string" ? (
            <Fragment key={idx}>{x}</Fragment>
          ) : (
            <InlineClozeComp data={x} key={idx} />
          )
        )}
      </code>
    </pre>
  );
};

const MarkdownInlineCode = (clozeDisplayStatusMap: ClozeDisplayStatusMap) => (
  props: any
) => {
  const InlineClozeComp = InlineCloze(clozeDisplayStatusMap);
  return (
    <code>
      {splitByClozeDeletion(props.value).map((x, idx) =>
        typeof x === "string" ? (
          <Fragment key={idx}>{x}</Fragment>
        ) : (
          <InlineClozeComp data={x} key={idx} />
        )
      )}
    </code>
  );
};

const InlineCloze = (clozeDisplayStatusMap: ClozeDisplayStatusMap) => ({
  data
}: {
  data: ClozeDeletion;
}) => {
  const status = clozeDisplayStatusMap[data.name] || "revealed";
  return <span className={`cloze ${status}`}>{data.answer}</span>;
};

export type MarkdownProps = ReactMarkdown.ReactMarkdownProps & {
  clozeDisplayStatusMap?: ClozeDisplayStatusMap;
};
const MarkdownUnstyled = (props: MarkdownProps) => {
  const clozeDisplayStatusMap = props.clozeDisplayStatusMap || {};
  return (
    <ReactMarkdown
      {...props}
      plugins={[cloze]}
      renderers={{
        code: MarkdownCodeBlock(clozeDisplayStatusMap),
        cloze: InlineCloze(clozeDisplayStatusMap),
        inlineCode: MarkdownInlineCode(clozeDisplayStatusMap)
      }}
    />
  );
};

export const Markdown = styled(MarkdownUnstyled)`
  * {
    box-sizing: border-box;
  }
  ul,
  ol {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  p {
    font-weight: 400;
    line-height: 1.8rem;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;
    color: ${grey800};
  }
  pre {
    color: ${grey700};
    margin: 0.5em 0 0.5em 0;
    width: fit-content;
    background-color: ${grey100};
    padding: 16px;
    border-radius: 3px;
    max-width: 100%;
    overflow-x: auto;

    code {
      background-color: unset;
      line-height: normal;
      padding: 0;
    }
  }
  code {
    font-size: 16px;
    background-color: ${grey100};
    padding: 2px 6px;
    line-height: 1.8rem;
  }
  em {
    font-weight: 600;
    font-style: normal;
  }
  img {
    max-width: 100%;
    max-height: 24em;
  }
  .cloze {
    padding: 2px;
    line-height: 1.8rem;
  }

  // See ClozeDisplayStatus
  .highlighted {
    color: ${blue900};
    border-bottom: 2px solid ${blue600};
  }
  .revealed {
    color: ${blue900};
    border-bottom: 2px solid ${blue600};
  }
  .hidden {
  }
  .answerable {
    color: ${blue600};
    border: 2px solid ${blue600};
  }
  .answeredCorrect {
    color: ${green800};
    border-bottom: 2px solid ${green800};
  }
  .answeredWrong {
    color: ${red600};
    border-bottom: 2px solid ${red600};
  }
  .skipped {
    color: ${red700};
    border-bottom: 2px solid ${red700};
  }
`;
/*---------------*/
function cloze() {
  // @ts-ignore
  const Parser = this.Parser;
  const tokenizers = Parser.prototype.inlineTokenizers;
  const methods = Parser.prototype.inlineMethods;
  tokenizers.cloze = tokenizeCloze;
  methods.splice(methods.indexOf("url"), 0, "cloze");
}
