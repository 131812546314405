import { DateTime, Duration } from "luxon";
import { clamp } from "ramda";
import { Correctness, ExerciseState } from "./Exercise/types";
import {
  exerciseStateIsCorrect,
  exerciseStateLastAnsweredAt
} from "./Exercise/reducer";
import { NoteState } from "./Note/types";
import { ExerciseStatus } from "./TaggedNote/types";

export type IntervalOld = {
  sourceQuestionId: string;
  currentDuration: Duration;
  upcomingTimestamp: Date;
};

const duration = (
  years: number = 0,
  months: number = 0,
  days: number = 0,
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0
): Duration =>
  Duration.fromObject({ years, months, days, hours, minutes, seconds });

// export const pimsleurIntervalDurations = [
//   duration(0, 0, 0, 0, 2),
//   duration(0, 0, 0, 0, 10),
//   duration(0, 0, 0, 1, 0),
//   duration(0, 0, 0, 5, 0),
//   duration(0, 0, 1, 0, 0),
//   duration(0, 0, 5, 0, 0),
//   duration(0, 0, 25, 0, 0),
//   duration(0, 4, 0, 0, 0),
//   duration(2, 0, 0, 0, 0)
// ];

export const myIntervalDurations = [
  duration(0, 0, 0, 0, 0, 5),
  duration(0, 0, 0, 6, 0, 0),
  duration(0, 0, 1, 0, 0, 0),
  duration(0, 0, 2, 0, 0, 0),
  duration(0, 0, 5, 0, 0, 0),
  duration(0, 0, 15, 0, 0, 0),
  duration(0, 1, 0, 0, 0, 0),
  duration(0, 3, 0, 0, 0, 0),
  duration(1, 0, 0, 0, 0, 0),
  duration(2, 0, 0, 0, 0, 0)
];

type IntervalWithLevel = IntervalOld & { level: number };
const nextLevelAux = (prevLevel: number, exercise: ExerciseState): number => {
  const correctness = exerciseStateIsCorrect(exercise);
  switch (correctness) {
    case "CORRECT":
      return prevLevel + 1;
    case "PARTIAL_CORRECT":
      return prevLevel;
    case "INCORRECT":
      return Math.max(prevLevel - 1, 0);
  }
};

const nextIntervalAuxOld = (durations: Duration[]) => (
  prevInterval: IntervalWithLevel,
  exerciseState: ExerciseState
): IntervalWithLevel => {
  const lastAnsweredAt =
    exerciseStateLastAnsweredAt(exerciseState) || exerciseState.createdAt;
  if (lastAnsweredAt < prevInterval.upcomingTimestamp) {
    // If I answered before the due date, it doesn't count
    return prevInterval;
  } else {
    const nextLevel = nextLevelAux(prevInterval.level, exerciseState);
    const currLevel = clamp(0, durations.length, nextLevel);
    const nextDuration = durations[currLevel];
    return {
      level: nextLevel,
      sourceQuestionId: prevInterval.sourceQuestionId,
      currentDuration: nextDuration,
      upcomingTimestamp: DateTime.fromJSDate(lastAnsweredAt)
        .plus(nextDuration)
        .toJSDate()
    };
  }
};

export const nextIntervalOld = (
  noteState: NoteState,
  exerciseStates: ExerciseState[],
  durations: Duration[]
) => {
  const currentDuration = myIntervalDurations[0];
  const firstInterval: IntervalWithLevel = {
    upcomingTimestamp: DateTime.fromJSDate(noteState.createdAt)
      .plus(currentDuration)
      .toJSDate(),
    currentDuration,
    sourceQuestionId: noteState.noteId,
    level: 0
  };
  return exerciseStates.reduce(nextIntervalAuxOld(durations), firstInterval);
};

/////

export type Interval = { currentDuration: Duration; upcomingTimestamp: Date, level: number };
const nextLevel = (prevLevel: number, correctness: Correctness): number => {
  switch (correctness) {
    case "CORRECT":
      return prevLevel + 1;
    case "PARTIAL_CORRECT":
      return prevLevel;
    case "INCORRECT":
      return Math.max(prevLevel - 1, 0);
  }
};

const nextIntervalAux = (durations: Duration[]) => (
  prevInterval: Interval,
  exerciseStatus: ExerciseStatus
): Interval => {
  const { timestamp, correctness } = exerciseStatus;
  if (timestamp < prevInterval.upcomingTimestamp) {
    // If I answered before the due date, it doesn't count
    return prevInterval;
  } else {
    const level = nextLevel(prevInterval.level, correctness);
    const currLevel = clamp(0, durations.length, level);
    const nextDuration = durations[currLevel];
    return {
      level: level,
      currentDuration: nextDuration,
      upcomingTimestamp: DateTime.fromJSDate(timestamp)
        .plus(nextDuration)
        .toJSDate()
    };
  }
};

export const nextInterval = (
  initialTimestamp: Date,  // think createdAt
  exerciseStatuses: ExerciseStatus[],
  durations: Duration[]
): Interval => {
  const currentDuration = myIntervalDurations[0];
  const firstInterval: Interval = {
    upcomingTimestamp: DateTime.fromJSDate(initialTimestamp)
      .plus(currentDuration)
      .toJSDate(),
    currentDuration,
    level: 0
  };
  return exerciseStatuses.reduce(nextIntervalAux(durations), firstInterval);
};
