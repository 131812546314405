import { getAvailableClozes } from "../Cloze/cloze";
import { ClozeDisplayStatusMap } from "../Exercise/types";
import { substituteClozes } from "../Cloze/renderer";
import { NoteContentBlock } from "../Note/types";

/**
 * Returns a single string that can be used to search against the note
 * @param content
 */
export const getSearchIndexText = (content: NoteContentBlock[]) => {
  const availableClozes = getAvailableClozes(content);
  const clozeDisplayStatusMap = availableClozes.reduce<ClozeDisplayStatusMap>(
    (accum, cloze) => {
      accum[cloze.name] = "revealed";
      return accum;
    },
    {}
  );
  return content
    .flatMap(c => substituteClozes(c.content, clozeDisplayStatusMap))
    .join(" ");
};
