export const black = "#000000";
export const grey900 = "#21252A";
export const grey800 = "#343A40";
export const grey700 = "#495057";
export const grey600 = "#868E96";
export const grey500 = "#ADB5BD";
export const grey400 = "#CED4DA";
export const grey300 = "#DEE2E6";
export const grey200 = "#E9ECEF";
export const grey100 = "#F1F3F5";
export const grey000 = "#F8F9FA";
export const white = "#FFFFFF";

export const blue900 = "#084F6D";
export const blue800 = "#136B90";
export const blue700 = "#217BAD";
export const blue600 = "#0F85C7";
export const blue500 = "#67C1F4";
export const blue400 = "#A6DBF9";
export const blue300 = "#D0ECFB";

export const red900 = "#970707";
export const red800 = "#BE0909";
export const red700 = "#EF0B0B";
export const red600 = "#F84B4B";
export const red500 = "#FA7A7A";
export const red400 = "#FCB0B0";
export const red300 = "#FFDBDB";

export const yellow900 = "#7F5A01";
export const yellow800 = "#936801";
export const yellow700 = "#AC7A01";
export const yellow600 = "#E4A40A";
export const yellow500 = "#F6B928";
export const yellow400 = "#F8CE68";
export const yellow300 = "#FDF1D2";

export const green900 = "#1A5935";
export const green800 = "#237947";
export const green700 = "#2FA360";
export const green600 = "#38C172";
export const green500 = "#9FE6BD";
export const green400 = "#C2EED4";
export const green300 = "#DEF6E8";

export const teal900 = "#284A46";
export const teal800 = "#376862";
export const teal700 = "#417C74";
export const teal600 = "#59A69C";
export const teal500 = "#6ECCC0";
export const teal400 = "#9EDDD5";
export const teal300 = "#DBF0ED";

export const purple800 = "#7e24fa";
// TODO: Get rest of purples
export const purple300 = "#e7defa";
