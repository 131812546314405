import {ConfigureNote, DeleteNote, NoteContentBlock} from "../Note/types";
import * as t from "io-ts";
import { DateCodec, DurationCodec } from "../IOTypes";
import { Correctness } from "../Exercise/types";
import {SaveTag, TagState} from "../Tag/types";

// ---------------------------
// Tagged Note Actions
// ---------------------------
// This represents all the actions that can be applied to note,
// including creating the note itself.
//

export const SaveTaggedNote = t.type({
  actionId: t.string,
  type: t.literal("SAVE_TAGGED_NOTE"),
  noteId: t.string,
  createdAt: DateCodec,
  content: t.array(NoteContentBlock),
  tags: t.array(TagState)
});
export type SaveTaggedNote = t.TypeOf<typeof SaveTaggedNote>;

export const CompleteExercise = t.type({
  actionId: t.string,
  exerciseId: t.string,
  noteId: t.string,
  createdAt: DateCodec,
  type: t.literal("COMPLETE_EXERCISE"),
  correctness: Correctness,
  timestamp: DateCodec,
})
export const TaggedNoteAction = t.union([
  SaveTaggedNote,
  ConfigureNote,
  DeleteNote,
  CompleteExercise,
  SaveTag,
])
export type TaggedNoteAction = t.TypeOf<typeof TaggedNoteAction>;
// ---------------------------
// Note State
// ---------------------------
// This represents the current state of a given note.
//

export const ExerciseStatus = t.type({
  timestamp: DateCodec,
  correctness: Correctness
});
export type ExerciseStatus = t.TypeOf<typeof ExerciseStatus>;

export const TaggedNote = t.type({
  noteId: t.string,
  createdAt: DateCodec,
  lastUpdatedAt: DateCodec,
  content: t.array(NoteContentBlock),
  currentDuration: DurationCodec,
  upcomingTimestamp: DateCodec,
  isDeleted: t.boolean,
  answerInOrder: t.boolean,
  hideOne: t.boolean,
  onlyFirstBlankAnswerable: t.boolean,
  tags: t.array(TagState),
  exerciseStatuses: t.array(ExerciseStatus)
});

export type TaggedNote = t.TypeOf<typeof TaggedNote>;
