import { clozeRegex, unescapeCloze } from "./parser";
import { NoteContentBlock } from "../Note/types";

/*
 * A cloze deletion is a block that looks like
 *
 * {{<name>::<answer>}}
 *
 * with an optional hint:
 *
 * {{<name>::<answer>::<hint>}}
 *
 * Clozes with the same name can appear multiple times within
 * a string. They will all be substituted with blanks simultaneously if that name
 * was chosen. In cases of conflicts, I will use the first answer as the canonical one.
 * */

export type ClozeDeletion = {
  name: string;
  answer: string;
  hint: string | null;
};

const stringToCloze = (str: string): ClozeDeletion => {
  const [name, answer, hint] = str.split("::");
  return {
    name,
    answer: unescapeCloze(answer),
    hint: hint || null
  };
};

export const splitByClozeDeletion = (
  question: string
): (string | ClozeDeletion)[] => {
  return question.split(clozeRegex).map((elem, idx) => {
    return idx % 2 === 1 ? stringToCloze(elem) : elem;
  }); // Every second element will be a cloze
};

export const getAvailableClozes = (content: NoteContentBlock[]) => {
  return content.flatMap(
    c =>
      splitByClozeDeletion(c.content).filter(
        elem => typeof elem !== "string"
      ) as ClozeDeletion[]
  );
};
