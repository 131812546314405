import React from "react";
import styled from "@emotion/styled";
import {
  blue300,
  blue800,
  green300,
  green800,
  purple300,
  purple800,
  red300,
  red800,
  teal300,
  teal800,
  white,
  yellow300,
  yellow800
} from "../colors";
import { useHistory } from "react-router";
import closeIcon from "../../assets/clear.svg";
import { TagColour, TagState} from "../../Tag/types";

export const TagDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.4rem;
  font-size: 14px;
  background-color: ${white};
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  width: fit-content;
  padding: 2px 6px;
  margin-right: 4px;
  vertical-align: center;
  font-weight: 600;
  white-space: nowrap;
  img {
    margin-left: 4px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const mapColour = (color: TagColour) => {
  switch (color) {
    case "BLUE":
      return blue800;
    case "GREEN":
      return green800;
    case "PURPLE":
      return purple800;
    case "RED":
      return red800;
    case "YELLOW":
      return yellow800;
    case "TEAL":
      return teal800;
  }
};

const mapBgColour = (color: TagColour) => {
  switch (color) {
    case "BLUE":
      return blue300;
    case "GREEN":
      return green300;
    case "PURPLE":
      return purple300;
    case "RED":
      return red300;
    case "YELLOW":
      return yellow300;
    case "TEAL":
      return teal300;
  }
};

export const EditableTag = ({
  tagName,
  tagColor,
  onClose
}: {tagName: string, tagColor: TagColour, onClose?: () => void }) => {
  const color = mapColour(tagColor);
  const bgColor = mapBgColour(tagColor);
  return (
    <TagDiv
      style={{
        color: color,
        backgroundColor: bgColor
      }}
    >
      {tagName}
      {onClose && (
        <button onClick={onClose}>
          <img src={closeIcon} alt={"close"} />
        </button>
      )}
    </TagDiv>
  );
};

export const Tag = ({ tagName, tagColor }: TagState) => {
  const color = mapColour(tagColor);
  const bgColor = mapBgColour(tagColor);
  const history = useHistory();
  const link = `/notes?tags=${encodeURIComponent(tagName)}`;
  return (
    <TagDiv
      style={{
        color: color,
        backgroundColor: bgColor
      }}
    >
      <a
        href={link}
        onClick={evt => {
          evt.preventDefault();
          history.push(link);
        }}
      >
        {tagName}
      </a>
    </TagDiv>
  );
};
