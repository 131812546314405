import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import styled from "@emotion/styled";
import produce from "immer";
import { PageWithNav } from "../page";
import { Title } from "../text";
import { ExerciseInterval, SummaryComponent } from "./summary";
import { myIntervalDurations } from "../../interval";
import { Page404 } from "./404";
import { asContent, LCE } from "../../LCE";
import { Loader } from "../loading";
import { Centered } from "../layout";
import { ExerciseComponent } from "../components/exerciseComponent";
import { ExerciseAction } from "../../Exercise/types";
import {
  exerciseStateIsAnswerable,
  initialExerciseState,
  createExerciseFromNote
} from "../../Exercise/reducer";
import { Store } from "../../Store";
import {TaggedNote} from "../../TaggedNote/types";

const ActiveQuestionWrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

export const ExercisesComponent = ({
  exerciseIntervals,
  setExerciseIntervals,
  store
}: {
  exerciseIntervals: ExerciseInterval[];
  setExerciseIntervals: (exerciseIntervals: ExerciseInterval[]) => void;
  store: Store;
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(
    exerciseIntervals.findIndex(({ exerciseState }) =>
      exerciseStateIsAnswerable(exerciseState)
    )
  );
  const currentExerciseInterval = exerciseIntervals[activeIndex];
  console.log(currentExerciseInterval);
  const setExerciseAction = useCallback(
    (action: ExerciseAction) => {
      store.enqueueAction(action).then(exerciseNote => {
        if (exerciseNote) {
          const newIntervals = produce(exerciseIntervals, d => {
            d[activeIndex].exerciseState = exerciseNote.exerciseState;
          });
          setExerciseIntervals(newIntervals);
          if (!exerciseStateIsAnswerable(exerciseNote.exerciseState)) {
            const nextIndex =
              activeIndex + 1 < exerciseIntervals.length ? activeIndex + 1 : -1;
            setTimeout(() => {
              setActiveIndex(nextIndex);
            }, 1500);
          }
        }
      });
    },
    [activeIndex, exerciseIntervals, setExerciseIntervals, store]
  );

  return (
    <PageWithNav navLocation={"Revision"}>
      {activeIndex !== -1 ? (
        <ActiveQuestionWrapper>
          <Title>Question {activeIndex + 1}</Title>
          <ExerciseComponent
            exerciseState={currentExerciseInterval.exerciseState}
            setExerciseAction={setExerciseAction}
          />
        </ActiveQuestionWrapper>
      ) : (
        <SummaryComponent exerciseIntervals={exerciseIntervals} />
      )}
    </PageWithNav>
  );
};

export const ExercisesByIdPage = ({
  id,
  store
}: {
  id: string;
  store: Store;
}) => {
  const [pageState, setPageState] = useState<LCE<ExerciseInterval[]>>({
    type: "Loading"
  });

  useEffect(() => {
    store
      .fetchExerciseNotesByGroupId(id)
      .then(exerciseNotes =>
        exerciseNotes.map(({ exerciseState, noteState }) => ({
          exerciseState,
          interval: {
            sourceQuestionId: noteState.noteId,
            currentDuration: noteState.currentDuration,
            upcomingTimestamp: noteState.upcomingTimestamp
          }
        }))
      )
      .then(xs => setPageState(asContent(xs)));
  }, [store, id]);
  switch (pageState.type) {
    case "Loading":
      return (
        <PageWithNav navLocation={"Revision"}>
          <Centered>
            <Loader />
          </Centered>
        </PageWithNav>
      );
    case "Content":
      return (
        <ExercisesComponent
          exerciseIntervals={pageState.content}
          setExerciseIntervals={exs => setPageState(asContent(exs))}
          store={store}
        />
      );
    case "Error":
      return <Page404 />;
  }
};

export const ExercisePageDemo = ({ store }: { store: Store }) => {
  const questionTemplates = [
    "The three key properties of a semilattice are {{A0::associativity}}, {{A1::commutativity}} and {{A2::idempotence}}",
    "In C#, the equivalent of the Filter function is `{{A0::Where}}`"
  ];
  const [exercises, setExercises] = useState(
    questionTemplates.map((questionTemplate, idx) => {
      const note: TaggedNote = {
        noteId: uuid(),
        content: [{ type: "MARKDOWN_BLOCK", content: questionTemplate }],
        tags: [],
        createdAt: new Date(),
        lastUpdatedAt: new Date(),
        answerInOrder: true,
        hideOne: false,
        onlyFirstBlankAnswerable: false,
        upcomingTimestamp: new Date(),
        currentDuration: myIntervalDurations[0],
        isDeleted: false,
        exerciseStatuses: []
      };
      return {
        exerciseState: initialExerciseState(
          createExerciseFromNote(note, uuid(), idx)
        ),
        interval: {
          sourceQuestionId: note.noteId,
          currentDuration: note.currentDuration,
          upcomingTimestamp: note.upcomingTimestamp
        }
      };
    })
  );
  return (
    <ExercisesComponent
      exerciseIntervals={exercises}
      setExerciseIntervals={setExercises}
      store={store}
    />
  );
};
