import React, { useRef, useState } from "react";
import { PrimaryButton } from "../button";
import { Manager, Popper, Reference } from "react-popper";
import styled from "@emotion/styled";
import newNote from "../../assets/new-note.svg";
import learn from "../../assets/learn.svg";
import expand from "../../assets/expand.svg";
import { blue300, grey200, white } from "../colors";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

const MenuContainer = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: ${white};
  padding: 0.5rem 0;
  border: 1px solid ${grey200};
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  margin-right: 0.1rem;
`;

const LeftButton = styled(PrimaryButton)`
  user-select: none;
  border-radius: 4px 0 0 4px;
  min-width: 112px;
`;
const RightButton = styled(PrimaryButton)`
  border-radius: 0 4px 4px 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  user-select: none;
  padding: 0;
`;
const MenuItem = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 12rem;
  user-select: none;
  padding: 0.5rem;
  font-size: 16px;
  img {
    margin-right: 0.5rem;
  }
  :active {
    background-color: ${blue300};
  }
  :hover {
    background: ${blue300};
  }
  :focus {
    background: ${blue300};
  }
`;

const MenuPopover = ({
  practiceCallback,
  newNoteCallback
}: {
  practiceCallback: () => void;
  newNoteCallback: () => void;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const popperRef = useRef(null);
  useOnClickOutside(popperRef, () => {
    setShowMenu(false);
  });
  return (
    <Manager>
      <div ref={popperRef}>
        <Reference>
          {({ ref }) => (
            <RightButton
              style={{ width: 32 }}
              ref={ref}
              onClick={evt => {
                evt.preventDefault();
                evt.stopPropagation();
                setShowMenu(s => !s);
              }}
            >
              <img src={expand} alt="expand" width={24} height={24} />
            </RightButton>
          )}
        </Reference>
        <Popper placement={"bottom-end"}>
          {({ ref, style, placement }) => {
            return (
              showMenu && (
                <div ref={ref} style={style} data-placement={placement}>
                  <MenuContainer>
                    <MenuItem
                      onClick={() => {
                        setShowMenu(false);
                        practiceCallback();
                      }}
                    >
                      <img src={learn} alt={"practice"} /> Practice
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setShowMenu(false);
                        newNoteCallback();
                      }}
                    >
                      <img src={newNote} alt={"new"} /> New Note
                    </MenuItem>
                  </MenuContainer>
                </div>
              )
            );
          }}
        </Popper>
      </div>
    </Manager>
  );
};

export const ActionMenu = ({
  practiceCallback,
  newNoteCallback
}: {
  practiceCallback: () => void;
  newNoteCallback: () => void;
}) => {
  return (
    <div
      className={"actionMenu"}
      style={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <LeftButton onClick={newNoteCallback}>+ New Note</LeftButton>
      <MenuPopover
        practiceCallback={practiceCallback}
        newNoteCallback={newNoteCallback}
      />
    </div>
  );
};
