import { Store } from "../../Store";
import React, { useState } from "react";
import { PageWithNav } from "../page";
import { ExerciseState } from "../../Exercise/types";
import styled from "@emotion/styled";
import { OptionGroup } from "../components/option";
import { TaggedNote } from "../../TaggedNote/types";

const Input = styled.input`
  font-size: 1.4rem;
  line-height: 1.4rem;
  height: 2rem;
`;
export const StoreDebuggerPage = ({ store }: { store: Store }) => {
  const [noteId, setNoteId] = useState("");
  const [note, setNote] = useState<TaggedNote | undefined>();
  const [exercises, setExercises] = useState<ExerciseState[]>([]);
  const fetchExercises = (noteId: string) => {
    store.exercises
      .fetchExercisesByNoteId(noteId)
      .then(exs => setExercises(exs));
    store.taggedNotes.fetchNoteById(noteId).then(note => note && setNote(note));
  };
  const [optionGroup, setOptionGroup] = useState<OptionGroup>({
    options: [
      {
        key: "hideOne",
        title: "Hide One",
        description: "Hides one answer at a time"
      },
      {
        key: "hideAll",
        title: "Hide All",
        description: "Hides all the unanswered answers"
      }
    ],
    selected: "hideOne"
  });
  return (
    <PageWithNav navLocation={null}>
      <p>Note ID</p>
      <Input onChange={evt => setNoteId(evt.target.value)} />
      <button onClick={() => fetchExercises(noteId)}>Fetch</button>
      <p>Note</p>
      <pre>{JSON.stringify(note, null, 2)}</pre>
      <p>Exercises</p>
      {exercises.map((ex, idx) => (
        <>
          <p>Exercise #{idx + 1}</p>
          <pre>{JSON.stringify(ex, null, 2)}</pre>
        </>
      ))}
      <OptionGroup
        optionGroup={optionGroup}
        onSelect={key => setOptionGroup({ ...optionGroup, selected: key })}
      />
    </PageWithNav>
  );
};
