import React, { useEffect, useState } from "react";
import { PageWithNav } from "../page";
import { useNow } from "../hooks/useNow";
import { asContent, LCE } from "../../LCE";
import { Paragraph, SubTitle, Title } from "../text";
import styled from "@emotion/styled";
import { PrimaryButton, SecondaryButton } from "../button";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { Store } from "../../Store";
import { NoteTable } from "../components/noteTable";
import { OptionGroup } from "../components/option";
import { TaggedNote } from "../../TaggedNote/types";

const FormSection = styled.div`
  margin: 0.5rem 0;
`;

const InputGroup = styled.div`
  margin: 0.5rem 0;
  align-items: center;
`;

const ButtonGroup = styled(InputGroup)`
  width: 100%;
  display: flex;
  flex-direction: row;

  button {
    margin-right: 12px;
  }
`;
const NotesDetailComponent = ({
  taggedNote,
  store
}: {
  taggedNote: TaggedNote;
  store: Store;
}) => {
  const [now] = useNow(60);
  const history = useHistory();
  const [answerInOrder, setAnswerInOrder] = useState(taggedNote.answerInOrder);
  const [hideOne, setHideOne] = useState(taggedNote.hideOne);
  const [onlyFirstBlankAnswerable, setOnlyFirstBlankAnswerable] = useState(
    taggedNote.onlyFirstBlankAnswerable
  );
  const updateSourceQuestion = () => {
    store.enqueueAction({
      type: "CONFIGURE_NOTE",
      actionId: uuid(),
      createdAt: new Date(),
      noteId: taggedNote.noteId,
      answerInOrder,
      hideOne,
      onlyFirstBlankAnswerable
    });
  };
  return (
    <>
      <Title>Note Settings</Title>
      <NoteTable notes={[taggedNote]} now={now} store={store} />
      <FormSection>
        <SubTitle>Answer order</SubTitle>
        <Paragraph>The order in which blanks are presented to you.</Paragraph>
        <InputGroup>
          <OptionGroup
            optionGroup={{
              options: [
                {
                  key: true,
                  title: "Sequential",
                  description:
                    "The blanks are presented in the order in which they were defined"
                },
                {
                  key: false,
                  title: "Random",
                  description: "The blanks are presented in random order"
                }
              ],
              selected: answerInOrder
            }}
            onSelect={key => setAnswerInOrder(key)}
          />
        </InputGroup>
      </FormSection>
      <FormSection>
        <SubTitle>Answers hidden</SubTitle>
        <Paragraph>
          The number of answers to hide at the start of an revision
        </Paragraph>
        <InputGroup>
          <OptionGroup
            optionGroup={{
              options: [
                {
                  key: true,
                  title: "Hide One",
                  description: "Hides one answer at a time"
                },
                {
                  key: false,
                  title: "Hide All",
                  description: "Hides all the unanswered answers"
                }
              ],
              selected: hideOne
            }}
            onSelect={key => setHideOne(key)}
          />
        </InputGroup>
      </FormSection>
      <FormSection>
        <SubTitle>Number of answerable blanks</SubTitle>
        <Paragraph>
          For questions with multiple blanks, this controls whether you can
          answer any of the blanks, or just one.
        </Paragraph>
        <InputGroup>
          <OptionGroup
            optionGroup={{
              options: [
                {
                  key: true,
                  title: "Only one blank may be answered",
                  description: ""
                },
                {
                  key: false,
                  title: "Any blank may be answered",
                  description: ""
                }
              ],
              selected: onlyFirstBlankAnswerable
            }}
            onSelect={key => setOnlyFirstBlankAnswerable(key)}
          />
        </InputGroup>
      </FormSection>
      <ButtonGroup>
        <PrimaryButton
          onClick={() => {
            updateSourceQuestion();
            history.goBack();
          }}
        >
          Save
        </PrimaryButton>
        <SecondaryButton
          onClick={async () => {
            const key = uuid();
            await store.newExerciseGroup(key, [taggedNote]);
            history.push(`/exercises/${key}`);
          }}
        >
          Try it out!
        </SecondaryButton>
      </ButtonGroup>
    </>
  );
};
export const NoteDetailsPage = ({
  noteId,
  store
}: {
  noteId: string;
  store: Store;
}) => {
  const [pageState, setPageState] = useState<LCE<TaggedNote>>({
    type: "Loading"
  });
  useEffect(() => {
    const noteSub = store.taggedNotes
      .observeNoteById(noteId)
      .subscribe(note => {
        if (note) {
          setPageState(asContent(note));
        } else {
          setPageState({
            type: "Error",
            error: `Note not found: ${noteId}`
          });
        }
      });
    return () => {
      noteSub.unsubscribe();
    };
  }, [store, noteId]);

  switch (pageState.type) {
    case "Loading":
      return <PageWithNav navLocation={"Notes"}>{null}</PageWithNav>;
    case "Content":
      return (
        <PageWithNav navLocation={"Notes"}>
          <NotesDetailComponent taggedNote={pageState.content} store={store} />
        </PageWithNav>
      );
    case "Error":
      return <PageWithNav navLocation={"Notes"}>{pageState.error}</PageWithNav>;
  }
};
