import React from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router";
import { Tag } from "./tag";
import { SmallText } from "../text";
import { grey200, grey600, grey700, white } from "../colors";
import { formatDatetimeDuration } from "../../datetime";
import { ButtonRow } from "../layout";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import styled from "@emotion/styled";
import { NoteContentRenderer } from "./noteContentRenderer";
import { highlightAllClozes } from "../../Note/clozeStatuses";
import { TaggedNote } from "../../TaggedNote/types";

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const QuestionCard = styled.div`
  background-color: ${white};
  padding: 0.8rem 1rem 0.8rem;
  border: 1px solid ${grey200};
  margin-bottom: 0.5rem;
`;

const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 2.5rem;
`;

export const NoteCard = React.memo(
  ({
    taggedNote,
    now,
    setDeletingNote,
    setEditing
  }: {
    taggedNote: TaggedNote;
    now: Date;
    setDeletingNote?: (note: TaggedNote) => void;
    setEditing?: (id: string) => void;
  }) => {
    const nowDateTime = DateTime.fromJSDate(now);
    const history = useHistory();
    const {content, tags, upcomingTimestamp, createdAt, noteId} = taggedNote;
    return (
      <QuestionCard>
        <NoteContentRenderer
          noteContents={content}
          clozeDisplayStatusMap={highlightAllClozes(content)}
        />
        <TagRow>{tags.map(t => t && <Tag {...t} key={t.tagId} />)}</TagRow>
        <Footer>
          <SmallText>
            {upcomingTimestamp < now ? (
              <span style={{ fontWeight: 600, color: grey700 }}>
                {`Due ${formatDatetimeDuration(
                  DateTime.fromJSDate(upcomingTimestamp),
                  nowDateTime
                )} · `}
              </span>
            ) : (
              `Due ${formatDatetimeDuration(
                DateTime.fromJSDate(upcomingTimestamp),
                nowDateTime
              )} · `
            )}
            Added{" "}
            {formatDatetimeDuration(
              DateTime.fromJSDate(createdAt),
              nowDateTime
            )}
          </SmallText>
          <ButtonRow>
            <a
              href={`/notes/${noteId}`}
              onClick={evt => {
                evt.preventDefault();
                history.push(`/notes/${noteId}`);
              }}
            >
              <SettingsIcon style={{ fill: grey600 }} />
            </a>
            {setDeletingNote && (
              <button onClick={() => setDeletingNote(taggedNote)}>
                <DeleteIcon style={{ fill: grey600 }} />
              </button>
            )}
            {setEditing && (
              <button onClick={() => setEditing(noteId)}>
                <EditIcon style={{ fill: grey600 }} />
              </button>
            )}
          </ButtonRow>
        </Footer>
      </QuestionCard>
    );
  }
);
