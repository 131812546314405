import styled from "@emotion/styled";
import { grey200, white } from "../colors";
import * as R from "ramda";
import React, { useEffect, useMemo, useState } from "react";
import { PageWithNav } from "../page";
import { Centered, LeftRightLayout, PageItem } from "../layout";
import { Title } from "../text";
import { SecondaryButton } from "../button";
import { asContent, LCE } from "../../LCE";
import { Loader } from "../loading";
import { useHistory } from "react-router";
import { SearchBar } from "../search";
import { ActionMenu } from "../components/actionMenu";
import { useNow } from "../hooks/useNow";
import { usePaginationLimits } from "../hooks/usePaginationLimits";
import { Store } from "../../Store";
import { TagState } from "../../Tag/types";
import { NoteTable } from "../components/noteTable";
import { NoteEditor } from "../components/noteEditor";
import { useAllTagsSubscription } from "../hooks/useAllTagsSubscription";
import { TaggedNote } from "../../TaggedNote/types";
import produce from "immer";
import { emptyTaggedNote } from "../../TaggedNote/reducer";
import { v4 as uuid } from "uuid";

export const QuestionCard = styled.div`
  background-color: ${white};
  padding: 0.8rem 1rem 0.8rem;
  border: 1px solid ${grey200};
  margin-bottom: 0.5rem;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const stringToRegexp = (s: string) => {
  try {
    return new RegExp(s, "i");
  } catch (e) {
    return new RegExp(s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "i");
  }
};

const TitleGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  .actionMenu {
    margin-left: 16px;
  }
`;
export const NotesPage = ({
  tags,
  store
}: {
  tags: string[];
  store: Store;
}) => {
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const { renderLimit, setRenderLimit, loadLimit } = usePaginationLimits(
    10,
    50
  );

  const [allNotes, setNotes] = useState<LCE<TaggedNote[]>>({ type: "Loading" });
  const [showNewNote, setShowNewNote] = useState(false);
  const history = useHistory();
  const [now] = useNow(60);
  const [search, setSearch] = useState("");
  const searchRegex = useMemo(() => stringToRegexp(search), [search]);
  useEffect(() => {
    const filter =
      tags.length > 0
        ? {
            searchIndexText: { $regex: searchRegex },
            tagNames: { $elemMatch: { $in: tags } }
          }
        : {
            searchIndexText: { $regex: searchRegex }
          };
    const sortTags = R.sortWith([
      R.ascend((t: TagState) => (tags.includes(t.tagName) ? -1 : 1)),
      R.ascend((t: TagState) => t.tagName)
    ]);
    const countSub = store
      .observeTaggedNotes(filter)
      .subscribe(allTaggedNotes => {
        setTotalCount(allTaggedNotes.length);
      });
    const sub = store
      .observeTaggedNotes(filter, loadLimit)
      .subscribe(taggedNotes => {
        setNotes(
          asContent(
            taggedNotes.map(tn =>
              produce(tn, d => {
                d.tags = sortTags(d.tags);
              })
            )
          )
        );
      });
    return () => {
      countSub.unsubscribe();
      sub.unsubscribe();
    };
  }, [tags, store, searchRegex, loadLimit]);
  const allTags = useAllTagsSubscription(store);
  switch (allNotes.type) {
    case "Loading":
      return (
        <PageWithNav navLocation={"Notes"}>
          <PageItem>
            <LeftRightLayout>
              <Title>
                {tags.length > 0 ? `All [${tags.join(" + ")}]` : "All"} notes{" "}
              </Title>
              <TitleGroup>
                <ActionMenu
                  practiceCallback={() => {}}
                  newNoteCallback={() => setShowNewNote(true)}
                />
              </TitleGroup>
            </LeftRightLayout>
          </PageItem>
          {showNewNote ? (
            <NoteEditor
              initialNote={emptyTaggedNote()}
              store={store}
              initialTags={allTags || []}
            />
          ) : (
            <SearchBar
              type={"text"}
              onChange={evt => {
                setSearch(evt.target.value);
              }}
              placeholder={"Search notes"}
              value={search}
            />
          )}
          <Centered>
            <Loader />
          </Centered>
        </PageWithNav>
      );
    case "Content":
      const rows = allNotes.content;
      const allNotesCount = totalCount !== null ? ` (${totalCount})` : "";

      const slicedNotes = rows.slice(0, renderLimit);
      const newExercisesCallback = async () => {
        const exercisesKey = uuid();
        await store.newExerciseGroup(exercisesKey, slicedNotes);
        history.push(`/exercises/${exercisesKey}`);
      };
      return (
        <PageWithNav navLocation={"Notes"}>
          <PageItem>
            <LeftRightLayout>
              <Title>
                {tags.length > 0 ? `All [${tags.join(" + ")}]` : "All"} notes{" "}
                {allNotesCount}
              </Title>
              <TitleGroup>
                <ActionMenu
                  practiceCallback={newExercisesCallback}
                  newNoteCallback={() => setShowNewNote(true)}
                />
              </TitleGroup>
            </LeftRightLayout>
          </PageItem>

          {showNewNote ? (
            <NoteEditor
              initialNote={emptyTaggedNote()}
              store={store}
              initialTags={allTags || []}
            />
          ) : (
            <SearchBar
              type={"text"}
              value={search}
              onChange={evt => {
                setSearch(evt.target.value);
              }}
              placeholder={"Search notes"}
            />
          )}

          <NoteTable
            notes={slicedNotes}
            now={now}
            store={store}
            key={"NotesTable"}
          />
          {totalCount !== null && totalCount > slicedNotes.length && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SecondaryButton onClick={() => setRenderLimit(l => l + 10)}>
                Show more
              </SecondaryButton>
            </div>
          )}
        </PageWithNav>
      );
    case "Error":
      return <Centered>Uh oh!</Centered>;
  }
};
