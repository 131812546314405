import { Dispatch, SetStateAction, useState } from "react";

type PaginationLimits = {
  renderLimit: number;
  setRenderLimit: Dispatch<SetStateAction<number>>;
  loadLimit: number;
};

export const usePaginationLimits = (
  initialRenderLimit: number = 10,
  loadBatchSize: number = 50
): PaginationLimits => {
  // RenderLimit is the number of notes to render
  const [renderLimit, setRenderLimit] = useState(initialRenderLimit);
  // LoadLimit is the number of notes to load into memory
  // We always want to load a little bit more than we're rendering
  const loadLimit = Math.ceil(renderLimit / loadBatchSize) * loadBatchSize + 1;
  return {
    renderLimit,
    setRenderLimit,
    loadLimit
  };
};
