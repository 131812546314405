import { ExerciseAction, ExerciseState } from "./types";
import { RxCollection, RxDocument, RxJsonSchema } from "rxdb";
import * as t from "io-ts";
import { DateCodec, tryDecode } from "../IOTypes";
///
/// ExerciseAction
///

export const ExerciseActionDocType = t.type({
  actionId: t.string,
  exerciseId: t.string,
  createdAt: DateCodec,
  payload: ExerciseAction
});
export type ExerciseActionDocType = t.OutputOf<typeof ExerciseActionDocType>;

type ExerciseActionDocMethods = {
  toExerciseAction: () => ExerciseAction;
};
export type ExerciseActionDocument = RxDocument<
  ExerciseActionDocType,
  ExerciseActionDocMethods
>;
type ExerciseActionCollectionMethods = {
  fromExerciseAction: (action: ExerciseAction) => object;
};
export type ExerciseActionCollection = RxCollection<
  ExerciseActionDocType,
  ExerciseActionDocMethods,
  ExerciseActionCollectionMethods
>;

export const exerciseActionSchema: RxJsonSchema<ExerciseActionDocType> = {
  title: "An action against an exercise",
  version: 0,
  type: "object",
  properties: {
    actionId: { type: "string", primary: true },
    exerciseId: { type: "string", index: true },
    createdAt: { type: "number", index: true },
    payload: { type: "object" }
  },
  required: ["actionId", "exerciseId", "createdAt", "payload"]
};

const exerciseActionDocMethods: ExerciseActionDocMethods = {
  toExerciseAction: function(this: ExerciseActionDocument): ExerciseAction {
    return tryDecode(ExerciseAction, this.payload);
  }
};

const exerciseActionCollectionMethods: ExerciseActionCollectionMethods = {
  fromExerciseAction: function(
    this: ExerciseActionCollection,
    action: ExerciseAction
  ) {
    return ExerciseActionDocType.encode({
      exerciseId: action.exerciseId,
      createdAt: action.createdAt,
      actionId: action.actionId,
      payload: action
    });
  }
};

export const exerciseActionCollectionOpts = {
  name: "exercise_actions",
  schema: exerciseActionSchema,
  methods: exerciseActionDocMethods,
  statics: exerciseActionCollectionMethods
};

// ---------------------------
// Exercise State
// ---------------------------
// Remember, this is not meant to be synced to other sites!
// Instead, it should be updated on ExerciseAction insert.

export const ExerciseDocType = t.type({
  exerciseId: t.string,
  noteId: t.string,
  updatedAt: DateCodec,
  exerciseGroupId: t.string,
  order: t.number,
  payload: ExerciseState
});
export type ExerciseDocType = t.OutputOf<typeof ExerciseDocType>;
type ExerciseDocMethods = {
  toExercise: () => ExerciseState;
};
export type ExerciseDocument = RxDocument<ExerciseDocType, ExerciseDocMethods>;

type ExerciseCollectionMethods = {
  fromExercise: (exercise: ExerciseState) => ExerciseDocType;
};
export type ExerciseCollection = RxCollection<
  ExerciseDocType,
  ExerciseDocMethods,
  ExerciseCollectionMethods
>;

export const exerciseSchema: RxJsonSchema<ExerciseDocType> = {
  title: "A workable exercise",
  version: 0,
  type: "object",
  properties: {
    exerciseId: { type: "string", primary: true },
    exerciseGroupId: { type: "string" },
    order: { type: "integer" },
    noteId: { type: "string", index: true },
    updatedAt: { type: "number", index: true },
    payload: { type: "object" }
  },
  compoundIndexes: [["exerciseGroupId", "order"]],
  required: [
    "exerciseId",
    "noteId",
    "updatedAt",
    "exerciseGroupId",
    "order",
    "payload"
  ]
};

const exerciseDocMethods: ExerciseDocMethods = {
  toExercise: function(this: ExerciseDocument) {
    return tryDecode(ExerciseState, this.toJSON().payload);
  }
};

const exerciseCollectionMethods: ExerciseCollectionMethods = {
  fromExercise: function(this: ExerciseCollection, ex: ExerciseState) {
    return ExerciseDocType.encode({
      payload: ex,
      exerciseId: ex.exerciseId,
      noteId: ex.note.noteId,
      exerciseGroupId: ex.exerciseGroupId,
      updatedAt: ex.updatedAt,
      order: ex.order
    });
  }
};

export const exerciseCollectionOpts = {
  name: "exercises",
  schema: exerciseSchema,
  methods: exerciseDocMethods,
  statics: exerciseCollectionMethods,
  autoMigrate: false
};
