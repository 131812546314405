import styled from "@emotion/styled";
import { grey200, grey400, grey500, grey800, white } from "./colors";
import * as React from "react";
import searchIcon from "../assets/search.svg";

const InputContainer = styled.div`
  background-color: ${white};
  padding: 0.6rem;
  border: 1px solid ${grey400};
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 0.3rem;
    fill: ${grey200};
  }
`;

const SearchBarInput = styled.input`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: ${grey800};
  height: 1.2rem;
  width: 100%;
  border: none;
  outline: none;
  ::placeholder {
    color: ${grey500};
  }
`;

export const SearchBar = (
  props: React.InputHTMLAttributes<HTMLInputElement>
) => {
  return (
    <InputContainer>
      <img src={searchIcon} width={24} alt={"search"} />
      <SearchBarInput {...props} />
    </InputContainer>
  );
};
