import gql from "graphql-tag";
import { GraphQLClient } from "graphql-request";
import { stringifyGql } from "../graphql";
import {
  TypeFetchActionQueryQuery,
  TypePushActionMutation,
  TypePushActionMutationVariables
} from "../__generated__/graphql-types";
import { AppDatabase } from "../Database";
import { Action } from "./types";
import * as t from "io-ts";
import { isRight } from "fp-ts/lib/Either";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ServerSyncDocType } from "./database";

export type SyncStatus = {
  // serverMaximumReceiptOrder is the highest number of actions currently reported by the server
  serverMaximumReceiptOrder: number | null;
  // clientHighWatermark is the highest number of actions currently synced on the client
  clientHighWatermark: number | null;
  // lastCheckedAt is the last time the app checked the server
  lastCheckedAt: Date;
};

const fetchActionsQuery = gql`
  query FetchActionQuery($serverReceiptOrder: Int!, $limit: Int!) {
    actions(
      limit: $limit
      where: { server_receipt_order: { _gt: $serverReceiptOrder } }
      order_by: [{ server_receipt_order: asc }]
    ) {
      actionPayload: action_payload
      serverReceiptOrder: server_receipt_order
    }
    actions_aggregate {
      aggregate {
        max {
          server_receipt_order
        }
      }
    }
  }
`;

const pushActionQuery = gql`
  mutation PushAction($actions: [actions_insert_input!]!) {
    insert_actions(
      objects: $actions
      on_conflict: { constraint: actions_action_id_key, update_columns: [] }
    ) {
      affected_rows
      returning {
        actionId: action_id
        action: action_payload
        serverReceiptOrder: server_receipt_order
      }
    }
  }
`;
const undefinedToNull = <T>(x: T | undefined): T | null =>
  x === undefined ? null : x;
const SyncedAction = t.type({
  actionPayload: Action,
  serverReceiptOrder: t.number
});
const SyncFetchResponse = t.type({
  actionPayloads: t.array(SyncedAction),
  serverMaximumReceiptOrder: t.union([t.number, t.null])
});
type SyncFetchResponse = t.TypeOf<typeof SyncFetchResponse>;

const fetchActions = (graphQLClient: GraphQLClient) => (
  serverReceiptOrder: number,
  limit: number
): Promise<SyncFetchResponse> => {
  return graphQLClient
    .request<TypeFetchActionQueryQuery>(stringifyGql(fetchActionsQuery), {
      serverReceiptOrder,
      limit
    })
    .then((data) => {
      const decodedPayloads = data.actions.map(act => SyncedAction.decode(act));
      const filtered = decodedPayloads.filter(isRight);
      if (filtered.length < decodedPayloads.length) {
        console.warn("Not all fetched actions could be decoded!");
        console.warn(decodedPayloads);
        console.warn(filtered);
      }
      const serverMaximumReceiptOrder = undefinedToNull(
        data.actions_aggregate.aggregate &&
          data.actions_aggregate.aggregate.max &&
          data.actions_aggregate.aggregate.max.server_receipt_order
      );
      return {
        actionPayloads: filtered.map(x => x.right),
        serverMaximumReceiptOrder
      };
    });
};

export const pushActionBuilder = (
  db: AppDatabase,
  graphQLClient: GraphQLClient
) => async (actions: Action[]) => {
  const variables: TypePushActionMutationVariables = {
    actions: actions.map(act => ({
      action_id: act.actionId,
      action_payload: Action.encode(act)
    }))
  };
  const result = await graphQLClient.request<TypePushActionMutation>(
    stringifyGql(pushActionQuery),
    variables
  );
  return (
    (await result) &&
    result.insert_actions &&
    result.insert_actions.returning.length > 0 &&
    result.insert_actions.returning.forEach(res => {
      db.server_sync_statuses.atomicUpsert(res);
    })
  );
};

/**
 * useFetcher returns a hook that periodically fetches actions from the server
 * @param db
 * @param graphQLClient
 * @param actionCallback
 */
export const useFetcher = (
  db: AppDatabase,
  graphQLClient: GraphQLClient | null,
  actionCallback: (action: Action) => Promise<unknown>
): SyncStatus | null => {
  const [serverMaximumReceiptOrder, setServerMaximumReceiptOrder] = useState<
    number | null
  >(null);
  const [highWatermark, setHighWatermark] = useState<number | null>(null);
  const [lastCheckedAt, setLastCheckedAt] = useState(new Date());
  const fetchActionsCallback = useMemo(() => {
    return graphQLClient && fetchActions(graphQLClient);
  }, [graphQLClient]);
  const syncActionsFromServer = useCallback(
    async (highWatermark: number) => {
      if (fetchActionsCallback && highWatermark !== null) {
        const syncResult = await fetchActionsCallback(highWatermark || 0, 300);
        setLastCheckedAt(new Date());
        setServerMaximumReceiptOrder(syncResult.serverMaximumReceiptOrder);
        for (const {
          actionPayload,
          serverReceiptOrder
        } of syncResult.actionPayloads) {
          // const exists = await db.server_sync_statuses
          //   .findOne()
          //   .where("actionId")
          //   .eq(actionPayload.actionId)
          //   .exec();
          // if (!exists) {
            await db.server_sync_statuses.insert({
              actionId: actionPayload.actionId,
              action: actionPayload,
              serverReceiptOrder: serverReceiptOrder
            });
            await actionCallback(actionPayload);
          // }
          setHighWatermark(serverReceiptOrder);
        }

        const nextHighWatermark =
          syncResult.actionPayloads.length > 0
            ? Math.max(
                ...syncResult.actionPayloads.map(x => x.serverReceiptOrder)
              )
            : highWatermark;
        const timeout =
          syncResult.serverMaximumReceiptOrder &&
          syncResult.serverMaximumReceiptOrder === nextHighWatermark
            ? 5000
            : 1000;
        await setTimeout(
          () => syncActionsFromServer(nextHighWatermark || 0),
          timeout
        );
      }
    },
    [fetchActionsCallback, actionCallback, db.server_sync_statuses]
  );
  useEffect(() => {
    // Set initial high watermark if not set
    db.server_sync_statuses
      .findOne()
      .sort({ serverReceiptOrder: "desc" })
      .exec()
      .then((res: ServerSyncDocType | null) => res && res.serverReceiptOrder)
      .then(serverReceiptOrder => {
        setHighWatermark(serverReceiptOrder);
        setTimeout(() => syncActionsFromServer(serverReceiptOrder || 0), 10);
      });
  }, [db, syncActionsFromServer]);

  return {
    clientHighWatermark: highWatermark,
    serverMaximumReceiptOrder,
    lastCheckedAt
  };
};
