import React from "react";
import { PageWithNav } from "../page";
import { PrimaryButton } from "../button";
import { Title } from "../text";
import { PageItem } from "../layout";
import { Store } from "../../Store";
import { SyncStatus } from "../../Store/sync";

type SettingsPageProps = {
  email: string;
  store: Store | null;
  resyncDb: () => void;
};
const displaySyncStatus = ({
  serverMaximumReceiptOrder,
  clientHighWatermark
}: SyncStatus) => {
  if (serverMaximumReceiptOrder === null || serverMaximumReceiptOrder === 0) {
    return "Server has nothing to sync";
  } else if (clientHighWatermark === null) {
    return "This device hasn't started syncing with the server";
  } else {
    return `This device is ${Math.floor(
      (clientHighWatermark * 100) / serverMaximumReceiptOrder
    )}% in sync with the server. (${clientHighWatermark}/${serverMaximumReceiptOrder})`;
  }
};
export function SettingsPage({ email, store, resyncDb }: SettingsPageProps) {
  return (
    <PageWithNav navLocation={"Settings"}>
      <PageItem>
        <Title>Sync status</Title>
        {store && store.syncStatus
          ? displaySyncStatus(store.syncStatus)
          : "There's nothing to sync with"}
        {store && store.syncStatus ? (
          <p>
            Last checked at {" "} {store.syncStatus.lastCheckedAt.toLocaleString()}
          </p>
        ) : null}
        <PrimaryButton style={{ width: 120 }} onClick={resyncDb}>
          Resync
        </PrimaryButton>
      </PageItem>
      <PageItem>
        <Title>Account</Title>
      </PageItem>
      <PageItem>You are logged in as {email}</PageItem>
      <PageItem>
        <PrimaryButton
          style={{ width: 120 }}
          onClick={() => {
            window.location.href = "/logout";
          }}
        >
          Log out
        </PrimaryButton>
      </PageItem>
    </PageWithNav>
  );
}
