// ----------------------------------------------------------------
// Server Sync Status
//
// This logs metadata around the sync statuses of all action types
// ----------------------------------------------------------------

import { RxCollection, RxJsonSchema } from "rxdb";
import { Action } from "./types";

export type ServerSyncDocType = {
  actionId: string;
  action: Action;
  serverReceiptOrder: number | null;
};

export type ServerSyncStatusCollection = RxCollection<ServerSyncDocType>;

const serverSyncSchema: RxJsonSchema<ServerSyncDocType> = {
  title: "The sync status of the actions against the server",
  version: 0,
  type: "object",
  properties: {
    actionId: { type: "string", primary: true },
    action: { type: "object" },
    serverReceiptOrder: { type: "number", index: true }
  },
  required: ["actionId", "action", "serverReceiptOrder"]
};

export const serverSyncCollectionOpts = {
  name: "server_sync_statuses",
  schema: serverSyncSchema
};
