import React, { useState } from "react";
import { CenteredPage } from "../page";
import { QuestionCard } from "./notes";
import styled from "@emotion/styled";
import { Title } from "../text";
import { Session } from "../../App";
import { PrimaryButton } from "../button";
import { grey800 } from "../colors";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

const TextField = styled.input`
  box-sizing: border-box;
  padding: 8px 4px;
  width: 100%;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;
  margin-block-start: 4px;
  margin-block-end: 4px;
  color: ${grey800};
`;

interface SignupResponse {
  email: string;
  token: string;
}

export const SignupPage = ({
  sessionCallback
}: {
  sessionCallback: (session: Session) => void;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const submitForm = () => {
    fetch("https://auth.blanknot.es/signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password })
    })
      .then(resp => {
        if (!resp.ok) {
          setError("Signup attempt was not successful");
          throw new Error(resp.statusText);
        } else {
          return resp.json() as Promise<SignupResponse>;
        }
      })
      .then(({ email, token }) => sessionCallback({ email, token }))
      .catch(err => console.log(err));
  };
  return (
    <CenteredPage>
      <QuestionCard>
        <Title>Signup</Title>
        {error && <p>{error}</p>}
        <Form
          onSubmit={evt => {
            evt.preventDefault();
            submitForm();
          }}
        >
          <TextField
            id="email"
            placeholder="Email address"
            type="email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
          />
          <TextField
            id="standard-password-input"
            placeholder="Password"
            type="password"
            autoComplete="current-password"
            onChange={evt => setPassword(evt.target.value)}
            style={{ minWidth: 300 }}
          />
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Form>
      </QuestionCard>
    </CenteredPage>
  );
};
