import styled from "@emotion/styled";
import * as React from "react";
import { blue600, grey600, white } from "./colors";
import { ReactComponent as LogoSmall } from "../assets/logo-small.svg";
import { useHistory } from "react-router";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem 2rem;
`;

const NavbarDiv = styled.div`
  height: 72px;
  //background-color: ${white};
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  padding: 0 0.5rem;
`;

const NavbarItem = styled.a`
  font-family: Source Sans Pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${grey600};
  height: 100%;
  margin-inline-start: 16px;
  vertical-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

const navbarItemSelected = {
  color: blue600,
  borderBottom: `4px solid ${blue600}`,
  marginBottom: -4
};
type NavLocation = "Home" | "Notes" | "Revision" | "Settings";
const Navbar = ({ navLocation }: { navLocation: NavLocation | null }) => {
  const history = useHistory();
  return (
    <NavbarDiv>
      <div>
        <a
          href={"/"}
          style={{ height: 48 }}
          onClick={evt => {
            evt.preventDefault();
            history.push("/");
          }}
        >
          <LogoSmall style={{ width: 48, height: 48 }} />
        </a>
        <NavbarItem
          style={navLocation === "Notes" ? navbarItemSelected : {}}
          href={"/notes"}
          onClick={evt => {
            evt.preventDefault();
            history.push("/notes");
          }}
        >
          Notes
        </NavbarItem>
        <NavbarItem
          style={navLocation === "Revision" ? navbarItemSelected : {}}
          href={"/revision"}
          onClick={evt => {
            evt.preventDefault();
            history.push("/revision");
          }}
        >
          Revision
        </NavbarItem>
        <NavbarItem
          style={navLocation === "Settings" ? navbarItemSelected : {}}
          href={"/settings"}
          onClick={evt => {
            evt.preventDefault();
            history.push("/settings");
          }}
        >
          Settings
        </NavbarItem>
      </div>
    </NavbarDiv>
  );
};

export const PageWithNav = ({
  navLocation,
  children
}: {
  navLocation: NavLocation | null;
  children: React.ReactNode;
}) => {
  return (
    <>
      <Navbar navLocation={navLocation} />
      <Page>{children}</Page>
    </>
  );
};
export const CenteredPage = styled(Page)`
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0;
  box-sizing: border-box;
  height: 90vh;
`;

export const Section = styled.section`
  padding: 2rem 0;
`;
